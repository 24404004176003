import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import cloud from '../../assets/padoongrich/cloud.png'
import moveCloud from '../../assets/padoongrich/Img-02.png'
import saveCost from '../../assets/padoongrich/Img-03.png'
import {
  BenefitBG, BenefitContent, BenefitDetailRowRender, Section, SideImage, StartSectionBenefit, TransparentButton,
} from './styled'

export const Benefits = ({ setVisible }) => (
  <div style={{ background: 'linear-gradient(180deg, #021245, #010934)' }}>
    <Section style={{ padding: '40px 0 80px 0' }}>
      <BenefitBG>
        <BenefitContent>
          <p className='detail'><img src={cloud} alt='cloud' style={{ width: 20, marginRight: '0.5rem' }} />CLOUD SERVER</p>
          <p className='header'>Closing the gaps in</p>
          <p className='semi-header'>the back office</p>
          <BenefitDetailRowRender>
            <p>Moving to Cloud</p>
            <p>“Data is now in sync across the organization. Konsys has streamlined all our costs and revenues from ERP to our accounting system. So I can see eliminated more than 50% of accountants work”</p>
            <TransparentButton onClick={() => setVisible(true)}>
              Read more
              <FontAwesomeIcon icon={solid('arrow-right')} style={{ marginLeft: 8 }}/>
            </TransparentButton>
          </BenefitDetailRowRender>
        </BenefitContent>
        <SideImage src={moveCloud} alt='moveCloud' align='right'/>
      </BenefitBG>
      <BenefitBG>
        <SideImage src={saveCost} alt='saveCost' align='left'/>
        <BenefitContent>
          <StartSectionBenefit />
          <p className='header'>Eliminating frauds,</p>
          <p className='header'>papers and conflicts</p>
          <BenefitDetailRowRender>
            <p>“The drivers used to come back to HQ to receive new shipment orders and submit shipment confirmations. Now they can jobs anywhere (on the road) so this makes our delivery process a lot more efficient.”</p>
            <TransparentButton onClick={() => setVisible(true)}>
              Read more
              <FontAwesomeIcon icon={solid('arrow-right')} style={{ marginLeft: 8 }}/>
            </TransparentButton>
          </BenefitDetailRowRender>
        </BenefitContent>
      </BenefitBG>
    </Section>
  </div>
)

export default Benefits
