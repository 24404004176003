import React from 'react'
import ArtWorkPic from '../assets/artwork.png'
import Center from '../assets/center.svg'
import {
  Artwork, EndSection, HomeContainer, StartButton,
} from '../components/styled'

export const Home = ({ myRef }) => (
  <HomeContainer>
    <h1>Better or<br className="mobile-only"></br> Different</h1>
    <p>At Konsys, we help your business thrive and grow with IT Solutions. So you can focus on serving your clients.</p>
    <StartButton onClick={ () => window.scrollTo(0, myRef.whoAreWeRef.current.offsetTop - 120)}>
      <p>Start Explore</p>
    </StartButton>
    {/* <div style={{ width: '87%', overflow: 'visible' }}> */}
    <Artwork>
      <div className="graffiti">
        <svg
          className="progress-ring"
          width="20"
          height="114">
            <defs>
            <linearGradient id="grad1" x1="10" y1="0" x2="10" y2="95" gradientUnits="userSpaceOnUse">
              <stop offset="0" stopColor="#3FF6CC" />
              <stop offset="1" stopColor="#0144FF" />
            </linearGradient>
            <linearGradient id="grad2" x1="1" y1="1" x2="0" y2="0">
              <stop offset="0" stopColor="#0144FF" />
              <stop offset="1" stopColor="#3FF6CC" />
            </linearGradient>
          </defs>
        <line
          x1='10'
          y1='0'
          x2='10'
          y2='95'
          strokeWidth="2"
          stroke="url(#grad1)"
         />
        <circle
          className="progress-ring__circle"
          strokeWidth="2"
          stroke="url(#grad2)"
          fill="transparent"
          r="9"
          cx="10"
          cy="104" />
        </svg>
      </div>
      <img src={ArtWorkPic} alt='ArtWorkPic' />
      <img src={Center} alt='Center' className='center'/>
    </Artwork>
  {/* </div> */}
  <EndSection />
  </HomeContainer >
)

export default Home
