import styled, { css } from 'styled-components'
import topBG from '../assets/BG/topBG.png'
import artWork from '../assets/artwork.png'
import topLight from '../assets/toplight.svg'
import ourService from '../assets/ourservice.svg'
import analytics from '../assets/analytics.svg'
import analyticsWhite from '../assets/analyticsWhite.svg'
import graphic from '../assets/graphic-tool.svg'
import graphicWhite from '../assets/graphic-toolWhite.svg'
import theTeam from '../assets/theteam.svg'
import coding from '../assets/coding.svg'
import codingWhite from '../assets/w-coding.svg'
import agile from '../assets/agile.svg'
import agileWhite from '../assets/w-agile.svg'
import growth from '../assets/growth.svg'
import growthWhite from '../assets/w-growth.svg'
import performance from '../assets/performance.svg'
import performanceWhite from '../assets/w-performance.svg'

export const Container = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  background: #000127 0% 0% no-repeat padding-box;
  opacity: 1;
  display: block;
`

export const Header = styled.div`
  width: 100%;
  background: transparent linear-gradient(180deg, #0E77FF26 0%, #000127 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
`

export const TopBG = styled.div`
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  background: transparent url(${topBG}) no-repeat padding-box;
  background-position: top;
  background-size: 100%;
  opacity: 1;
  overflow: hidden;
  @media only screen and (max-width: 1440px) {
    background: none;
  }
`

export const MobileBG = styled.div`
  background: none;
  width: 100%;
  @media only screen and (max-width: 1440px) {
    background: linear-gradient(180deg, #03205f, #000127 83%);
  }
`

export const TopLight = styled.div`
  width: 100%;
  height: 203px;
  background: transparent url(${topLight}) no-repeat padding-box;
  background-position: center;
  mix-blend-mode: screen;
  opacity: 1;
  @media only screen and (max-width: 768px) {
    height: 103px;
  }
`

export const StartSection = styled.div`
  position: relative;
  width: 47px;
  height: 4px;
  background: transparent linear-gradient(271deg, #0144FF 0%, #3FF6CC 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
`

export const ProjectButton = styled.div`
  position: relative;
  width: 184px;
  height: 48px;
  cursor: pointer;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 23px 26px #0001274B;
  border-radius: 50px;
  margin: 34px 0 0 0;
  opacity: 1;
  transition: all 0.3s;
  > p{
    position: relative;
    top: 14px;
    left: 36.56px;
    width: 110px;
    height: 21px;
    text-align: center;
    white-space: nowrap;
    font-size: 18px;
    font-family: Raleway-SemiBold;
    line-height: 21px;
    letter-spacing: 0;
    margin: 0;
    color: #000127;
    text-transform: capitalize;
    opacity: 1;
  }
  &:hover {
    background: rgb(255,255,255, 0.9) 0% 0% no-repeat padding-box;
    > p {
      /* color: rgb(0,1,39, 0.9); */
    }
  }
`

export const P = styled.p`
  position: relative;
  width: auto;
  height: 68px;
  text-align: center;
  font-size: 26px;
  white-space: nowrap;
  font-family: Raleway-Regular;
  line-height: 30px;
  margin: 23px 0 0 0;
  letter-spacing: 0;
  color: #FFFFFF;
  opacity: 1;
`

export const ButtonText = styled.p`
  position: relative;
  top: 14px;
  left: 36.56px;
  width: 110px;
  height: 21px;
  text-align: center;
  white-space: nowrap;
  font-size: 18px;
  font-family: Raleway-SemiBold;
  line-height: 21px;
  letter-spacing: 0;
  margin: 0;
  color: #FFFFFF;
  text-transform: capitalize;
  opacity: 1;
`

// menu //
export const MenuBar = styled.div`
  width: 87.5%;
  position: absolute;
  /* margin-top: 64px; */
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  padding: 64px 6.25% 24px;
  transition: all 0.4s ease-in-out;
  color: #FFFFFF;
  background: none;
  @media only screen and (max-width: 1200px)  {
    background: ${({ isOpen }) => (isOpen ? 'linear-gradient(90deg, #0E0F31 0%, #02042A 100%) 0% 0% no-repeat padding-box' : 'none')};
  }
  @media only screen and (max-width: 768px)  {
    padding-top: 30px;
    padding-bottom: 20px;
  }
  > img {
    width: 183px;
    cursor: pointer;
  }
  > p {
    font-family: Raleway-Medium;
    font-size: 18px;
    margin: 0;
  }
`

export const FixMenuBar = styled.div`
  width: 87.5%;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  background: linear-gradient(90deg, #0E0F31 0%, #02042A 100%) 0% 0% no-repeat padding-box;
  padding: 20px 6.25%;
  color: #FFFFFF;
  > img {
    width: 153px;
    cursor: pointer;
  }
  > p {
    font-family: Raleway-Medium;
    font-size: 15px;
    margin: 0;
  }
`

export const DefaultMenu = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 153px;
    cursor: pointer;
  }
  > p {
    font-family: Raleway-Medium;
    font-size: 15px;
    margin: 0;
  }
`

export const VerticalMenu = styled.div`
  display: flex;
  flex-direction: column;
  max-height: ${({ isOpen }) => (isOpen ? '1000px' : '0')};
  transition: max-height 0.4s ease-in-out;
  overflow: hidden;
  > p {
    margin: 25px 0 0 0;
    font-family: Raleway-SemiBold;
    color: rgba(255, 255, 255, 0.75);
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    &:last-child{
      margin: 25px 0 5px 0;
    }
  }
  > p:hover {
    color: rgba(255, 255, 255, 1);
  }
  ${props => props.header && css`
    opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  `}
`

export const MenuContent = styled.div`
  font-family: Raleway-SemiBold;
  font-size: 16px;
  letter-spacing: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  > div {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(255, 255, 255, 0.75);
    border-radius: 20px;
    width: 110px;
    height: 36px;
    margin: 0 0 0 60px;
    transition: all 0.3s ease-in-out;
    @media only screen and (max-width: 1440px) {
      margin: 0 0 0 40px;
    }
    > p {
      font-family: Raleway-SemiBold;
      transition: all 0.3s ease-in-out;
      color: rgba(255, 255, 255, 0.75);
      font-size: 16px;
      margin: 0;
    }
  }
  > div:hover {
    width: 110px;
    height: 36px;
    border: 1px solid #3FF6CC;
    border-radius: 20px;
    > p {
      color: rgba(255, 255, 255, 1);
      color: #3FF6CC;
    }
  }
  > p {
    cursor: pointer;
    margin: 0 0 0 85px;
    color: rgba(255, 255, 255, 0.75);
    transition: all 0.3s ease-in-out;
    @media only screen and (max-width: 1440px) {
      margin: 0 0 0 65px;
    }
  }
  > p:hover {
    /* color: rgba(255, 255, 255, 1); */
    color: #3FF6CC;
  }
  @media only screen and (max-width: 1199px)  {
    display: none;
  }
`
// end menu //

// home //
export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > h1 {
    position: relative;
    /* white-space: nowrap; */
    width: auto;
    text-align: center;
    font-size: 120px;
    font-family: Circular;
    line-height: 152px;
    font-style: Black;
    margin: 35px 0 0 0;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
    @media only screen and (max-width: 1440px) {
    font-size: 90px;
    line-height: 100px;
    }
    @media only screen and (max-width: 768px) {
    font-size: 60px;
    line-height: 70px;
    }
    @media only screen and (max-width: 425px) {
    /* font-size: 60px; */
    /* line-height: 1; */
    font-size: 17vw;
    line-height: 17vw;
    margin: 0;
    }
  }
  > p {
    position: relative;
    width: 42%;
    text-align: center;
    font-size: 26px;
    font-family: Raleway-Regular;
    line-height: 30px;
    margin: 23px 0 0 0;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
    @media only screen and (max-width: 1440px) {
    font-size: 20px;
    line-height: 26px;
    width: 80%;
    }
    @media only screen and (max-width: 425px) {
    font-family: Raleway-Medium;
    /* font-size: 16px; */
    font-size: 20px;
    margin: 16px 0 0 0;
    width: auto;
    padding: 0 24px;
    }
  }
  .graffiti {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 52px;
    @media only screen and (max-width: 1440px) {
    display: none;
    }
  }
  > img {
    display: none;
    width: 100%;
    @media only screen and (max-width: 1440px) {
    display: block;
    }
  }
`

export const StartButton = styled.div`
  position: relative;
  width: 184px;
  height: 48px;
  cursor: pointer;
  background: transparent linear-gradient(329deg, #0144FF 0%, #3FF6CC 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 23px 26px #0001274B;
  border-radius: 50px;
  margin: 34px 0 0 0;
  opacity: 1;
  transition: all 0.3s;
  z-index: 97;
  @media only screen and (max-width: 425px) {
  /* margin: 30px 0 0 0; */
  width: 160px;
  height: 40px;
  }
  > p{
    position: relative;
    top: 14px;
    left: 36.56px;
    width: 110px;
    height: 21px;
    text-align: center;
    white-space: nowrap;
    font-size: 18px;
    font-family: Raleway-SemiBold;
    line-height: 21px;
    letter-spacing: 0;
    margin: 0;
    color: #FFFFFF;
    text-transform: capitalize;
    opacity: 1;
    @media only screen and (max-width: 425px) {
    font-size: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
  }
  }
  &:hover {
    background: transparent linear-gradient(329deg, rgb(1,68,255, 0.9) 0%, rgb(63,246,204, 0.9) 100%) 0% 0% no-repeat padding-box;
    > p {
      color: rgb(255,255,255, 0.9);
    }
  }
`

export const Artwork = styled.div`
  position: relative;
  width: 87%;
  margin-top: 25px;
  height: 979px;
  background: transparent url(${artWork}) 0% 0% no-repeat padding-box;
  background-position: center;
  background-size: cover;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: visible;
  @media only screen and (max-width: 1440px) {
    background: none;
    height: auto;
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
  margin: 5px 0 0 0;
  }
  > img {
    width: 100%;
    display: none;
    @media only screen and (max-width: 1440px) {
    display: flex;
    }
    @media only screen and (max-width: 768px) {
      width: 120%;
    }
    @media only screen and (max-width: 425px) {
      display: none;
    }
  }
  > img.center {
    width: 140%;
    margin-top: -40px;
    display: none;
    @media only screen and (max-width: 425px) {
      display: flex;
      width: 165%;
    }
  }
`

export const EndSection = styled.div`
  position: relative;
  width: 47px;
  height: 4px;
  background: transparent linear-gradient(271deg, #0144FF 0%, #3FF6CC 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  margin: -37px 0 0 0;
  @media only screen and (max-width: 1440px) {
  margin: 20px 0 0 0;
  }
  @media only screen and (max-width: 425px) {
  margin: 52px 0 0 0;
  }
`
// end home //

// who we are //
export const WhoWeAreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > p.header {
  position: relative;
  width: auto;
  text-align: center;
  font-size: 26px;
  white-space: nowrap;
  line-height: 30px;
  color: #FFFFFF;
  opacity: 1;
  margin: 40px 0 0 0;
  font-family: Raleway-Bold;
  letter-spacing: 3.9px;
  @media only screen and (max-width: 1440px) {
  font-size: 20px;
  }
  @media only screen and (max-width: 425px) {
    margin: 16px 0 0 0;
    font-size: 16px;
    letter-spacing: 1.5px;
  }
  }
  > p.motto {
  position: relative;
  width: 36%;
  text-align: center;
  font-size: 32px;
  line-height: 46px;
  color: #FFFFFF;
  opacity: 1;
  margin: 58px 0 0 0;
  font-family: Raleway-Bold;
  letter-spacing: 0;
  @media only screen and (max-width: 1440px) {
  font-size: 24px;
  line-height: 34px;
  width: 80%;
  margin-top: 30px;
  }
  @media only screen and (max-width: 425px) {
    margin: 24px 0 0 0;
    font-size: 18px;
  line-height: normal;
  letter-spacing: normal;
  font-stretch: normal;
  font-style: normal;
    width: auto;
    padding: 0 24px;
  }
  }
  > p.detail {
  position: relative;
  width: 55%;
  text-align: center;
  font-size: 18px;
  line-height: 32px;
  color: #FFFFFF;
  opacity: 1;
  margin: 50px 0 90px 0;
  font-family: Raleway-Medium;
  letter-spacing: 0;
  @media only screen and (max-width: 1440px) {
  font-size: 16px;
  width: 85%;
  margin-top: 30px;
  line-height: 28px;
  margin-bottom: 50px;
  }
  @media only screen and (max-width: 425px) {
    margin: 23px 0 32px 0;
    font-size: 14px;
  line-height: 1.57;
    width: auto;
    padding: 0 24px;
  }
}
`
// end who we are //

// our services //
export const OurServiceBG = styled.div`
  position: relative;
  width: 100%;
  background: transparent linear-gradient(90deg, rgba(0,1,39,0.1) 0%, rgba(0,40,210,0.1) 13%, rgba(0,40,210,0.1) 87%, rgba(0,1,39,0.1) 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  flex-direction: row;
`

export const OurServicePicture = styled.div`
  position: relative;
  top: 0px;
  width: 36%;
  margin-top: 38px;
  margin-right: -100px;
  height: 734px;
  background: transparent url(${ourService}) no-repeat padding-box;
  background-position: top -40px right 0px;
  background-size: 200%;
  opacity: 1;
  z-index: 1;
  @media only screen and (max-width: 1024px) {
  opacity: 0.2;
}
  @media only screen and (max-width: 768px) {
  display: none;
}
@media only screen and (max-width: 425px) {
  display: block;
  width: 47%;
  margin-right: -47%;
  margin-top: -20px;
  background-size: 160%;
  background-position: top 0px right 0px;
}
`

export const StartSectionOurService = styled.div`
  position: relative;
  width: 47px;
  height: 4px;
  background: transparent linear-gradient(271deg, #0144FF 0%, #3FF6CC 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: 75px;
  @media only screen and (max-width: 768px) {
  margin-top: 40px;
}
@media only screen and (max-width: 425px) {
  margin-top: 75px;
}
`

export const OurServiceContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  > p.header {
  position: relative;
  text-align: center;
  font-size: 26px;
  white-space: nowrap;
  font-family: Raleway-ExtraBold;
  line-height: 30px;
  margin: 40px 0 0 0;
  letter-spacing: 3.9px;
  color: #FFFFFF;
  opacity: 1;
  @media only screen and (max-width: 1440px) {
    font-size: 20px;
    /* margin-top: 30px; */
    }
    @media only screen and (max-width: 425px) {
    margin: 16px 0 0 0;
    font-size: 14px;
    font-family: Raleway-Bold;
    letter-spacing: 1.5px;
    }
  }
  > p.detail {
  position: relative;
  text-align: center;
  font-size: 22px;
  line-height: 26px;
  color: #FFFFFF;
  opacity: 1;
  margin: 30px 0 48px 0;
  font-family: Raleway-Regular;
  letter-spacing: 0;
  @media only screen and (max-width: 1440px) {
    font-size: 16px;
    width: 85%;
    margin-top: 20px;
    margin-bottom: 20px;
    }
    @media only screen and (max-width: 425px) {
    margin-top: 24px;
    margin-bottom: 32px;
    font-stretch: normal;
    font-family: Raleway-Medium;
    font-weight: 500;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    width: calc(100% - 48px);
    }
  }
`

export const OurServiceDetailRowRender = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  margin: 40px 0 0 0;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    width: auto;
    margin-top: 0px;
  }
  @media only screen and (max-width: 425px) {
    width: calc(100% - 48px);
    padding: 0 24px;
  }
`

export const OurServiceDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 11px 0;
    @media only screen and (max-width: 425px) {
      margin: 0 0 0px 0;
      align-items: flex-start;
      flex-direction: row-reverse;
      justify-content: space-between;
      height: 22px;
      overflow: visible;
      margin-bottom: 8px;
    }
    > p {
      position: relative;
      font-size: 28px;
      line-height: 50px;
      color: #FFFFFF;
      opacity: 1;
      margin: 0;
      font-family: Raleway-Bold;
      letter-spacing: 0;
      @media only screen and (max-width: 768px) {
        font-size: 22px;
        line-height: 30px;
      }
    @media only screen and (max-width: 425px) {
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
    }
    }
    > img {
      margin-right: 54px;
      @media only screen and (max-width: 768px) {
        margin-right: 54px;
      }
      @media only screen and (max-width: 425px) {
        /* display: none; */
      }
    }
  }
  > p {
    margin: 0 0 0 94px;
    font-family: Raleway-Regular;
    font-size: 20px;
    line-height: 32px;
    color: #FFFFFF;
    @media only screen and (max-width: 768px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 425px) {
      font-size: 14px;
      margin: 0px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: #e9e9e9;
    }
  }
  ${props => props.design && css`
    .title {
      > img {
        width: 39.21px;
        margin-left: 0.79px;
        @media only screen and (max-width: 425px) {
          margin: 0px;
        }
      }
    }
  `}
  ${props => props.web && css`
    margin-bottom: 72px;
    @media only screen and (max-width: 425px) {
      margin: 25px 0 0 0;
    }
    .title {
      > img {
        width: 39.87px;
        margin-left: 0.13px;
        @media only screen and (max-width: 425px) {
          margin: 0px;
        }
      }
    }
  `}
  ${props => props.mvp && css`
    .title {
      > img {
        width: 36.02px;
        margin-left: 3.98px;
        @media only screen and (max-width: 425px) {
          margin: 0px;
        }
      }
    }
  `}
  ${props => props.mobile && css`
    @media only screen and (max-width: 768px) {
      margin-bottom: 100px;
    }
    @media only screen and (max-width: 425px) {
      margin-bottom: 70px;
    }
    .title {
      > img {
        width: 38.39px;
        margin-left: 1.61px;
        @media only screen and (max-width: 425px) {
          margin: 0px;
          /* width: 20px; */
        }
      }
    }
    `}
  &:last-child {
  margin-top: 40px;
    @media only screen and (max-width: 425px) {
      margin-top: 25px;
    }
  }
`

export const Stack = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  margin: -75px 0 0 0;
  width: 84%;
  height: 180px;
  background: #D9DBE3 0% 0% no-repeat padding-box;
  border-radius: 100px;
  opacity: 1;
  z-index: 0;
  @media only screen and (max-width: 768px) {
    margin: -35px 0 0 0;
    height: auto;
    flex-wrap: wrap;
    border-radius: 100px;
    padding: 25px;
    justify-content: space-evenly;
  }
  @media only screen and (max-width: 425px) {
    /* padding: 0 15px 10px 15px; */
    border-radius: 6px;
    width: calc(100% - 48px);
    margin: -60px 0 0 0;
    padding: 5px 0 10px 0;
    background: #FFFFFF;
  }
`

export const StackShadow = styled.div`
  display: none;
  @media only screen and (max-width: 425px) {
    /* padding: 0 15px 10px 15px; */
    display: flex;
    margin-top: -12px;
    border-radius: 6px;
    width: calc(100% - 48px);
    background-image: linear-gradient(180deg, #22a3e4 50%, #39e6d0);
    height: 19px;
  }
`
// end our services //

// our projects //
export const OurProjectsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
  > p {
    position: relative;
    text-align: center;
    font-size: 26px;
    font-family: Raleway-Bold;
    line-height: 30px;
    margin: 40px 0 0 0;
    letter-spacing: 3.9px;
    color: #FFFFFF;
    opacity: 1;
    @media only screen and (max-width: 768px) {
      font-size: 20px;
    }
    @media only screen and (max-width: 425px) {
      font-size: 14px;
      margin: 16px 0 0 0;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.5px;
    }
  }
`

export const StartSectionOurProjects = styled.div`
  position: relative;
  width: 47px;
  height: 4px;
  background: transparent linear-gradient(271deg, #0144FF 0%, #3FF6CC 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: 96px;
  @media only screen and (max-width: 768px) {
  margin-top: 40px;
}
  @media only screen and (max-width: 425px) {
  margin-top: 101px;
}
`

export const ProjectContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  max-height: ${({ showAll }) => (showAll ? 'auto' : '930px')};
  margin: 63px 0 0 0;
  transition: max-height 0.4s ease-in-out;
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

export const ProjectColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
  margin: 0 16px;
  overflow: hidden;
  &:hover {
    overflow: visible;
  }
  > div.box-suit {
    > img {
    width: 100%;
  }
  }
`
// end our projects //

// how wo work //
export const HowWeWorkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > p {
    position: relative;
    text-align: center;
    font-size: 26px;
    font-family: Raleway-Bold;
    line-height: 30px;
    margin: 40px 0 0 0;
    letter-spacing: 3.9px;
    color: #FFFFFF;
    opacity: 1;
    @media only screen and (max-width: 768px) {
      font-size: 20px;
      line-height: 24px;
    }
    @media only screen and (max-width: 425px) {
      margin: 16px 0 0 0;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.5px;
    }
  }
  > p.sub-header {
    position: relative;
    width: 750px;
    text-align: center;
    font-size: 22px;
    font-family: Raleway-Regular;
    line-height: 30px;
    margin: 33px 0 0 0;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
    @media only screen and (max-width: 768px) {
      width: 85%;
      font-size: 18px;
      line-height: 24px;
      margin: 20px 0 0 0;
    }
    @media only screen and (max-width: 425px) {
      font-family: Raleway-Medium;
      margin: 24px 0 0 0;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: center;
      width: calc(100% - 48px);
    }
  }
`

export const StartSectionHowWeWork = styled.div`
  position: relative;
  width: 47px;
  height: 4px;
  background: transparent linear-gradient(271deg, #0144FF 0%, #3FF6CC 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: 152px;
  @media only screen and (max-width: 768px) {
  margin-top: 60px;
}
  @media only screen and (max-width: 425px) {
  margin-top: 75px;
}
`

export const DetailBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 1140px;
  margin: 41px 0 0 0;
  flex-wrap: wrap;
  @media only screen and (max-width: 1440px) {
    width: 100%;
    justify-content: center;
  }
  @media only screen and (max-width: 425px) {
    margin: 30px 0 0 0;
  }
  .how-we-work {
    width: 348px;
    height: 415px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 30px 60px #000127;
    opacity: 1;
    z-index: 1;
    margin: 0 0 41px 32px;
    > p.main {
      text-align: left;
      font-family: Raleway-Bold;
      font-size: 20px;
      letter-spacing: 0;
      color: #000127;
      margin-left: 32px;
      margin-top: 35px;
      margin-bottom: 0px;
      opacity: 1;
    }
    > p.detail {
      text-align: left;
      font-family: Poppins-Regular;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 25px;
      color: #000127;
      margin: 16px 32px;
      opacity: 1;
    }
    .analytic {
      width: 49px;
      height: 49px;
      margin: 84px 0 0 32px;
      background: transparent url(${analytics}) no-repeat padding-box;
      background-position: center;
      background-size: contain;
      opacity: 1;
    }
    .design {
      width: 49px;
      height: 49px;
      margin: 84px 0 0 32px;
      background: transparent url(${graphic}) no-repeat padding-box;
      background-position: center;
      background-size: contain;
      opacity: 1;
    }
    .coding {
      width: 53px;
      height: 53px;
      margin: 80px 0 0 32px;
      background: transparent url(${coding}) no-repeat padding-box;
      background-position: center;
      background-size: contain;
      opacity: 1;
    }
    .agile {
      width: 63px;
      height: 49px;
      margin: 84px 0 0 32px;
      background: transparent url(${agile}) no-repeat padding-box;
      background-position: center;
      background-size: contain;
      opacity: 1;
    }
    .growth {
      width: 55px;
      height: 55px;
      margin: 78px 0 0 32px;
      background: transparent url(${growth}) no-repeat padding-box;
      background-position: center;
      background-size: contain;
      opacity: 1;
    }
    .performance {
      width: 53px;
      height: 53px;
      margin: 80px 0 0 32px;
      background: transparent url(${performance}) no-repeat padding-box;
      background-position: center;
      background-size: contain;
      opacity: 1;
    }
    &:hover {
      background: transparent linear-gradient(0deg, #0028D2 0%, #3FF6CC 100%) 0% 0% no-repeat padding-box;
      > p.main {
        color: #FFFFFF;
      }
      > p.detail {
        color: #FFFFFF;
      }
      .analytic {
        background: transparent url(${analyticsWhite}) no-repeat padding-box;
        background-position: center;
        background-size: contain;
      }
      .design {
        background: transparent url(${graphicWhite}) no-repeat padding-box;
        background-position: center;
        background-size: contain;
      }
      .coding {
      background: transparent url(${codingWhite}) no-repeat padding-box;
      background-position: center;
      background-size: contain;
    }
    .agile {
      background: transparent url(${agileWhite}) no-repeat padding-box;
      background-position: center;
      background-size: contain;
    }
    .growth {
      background: transparent url(${growthWhite}) no-repeat padding-box;
      background-position: center;
      background-size: contain;
    }
    .performance {
      background: transparent url(${performanceWhite}) no-repeat padding-box;
      background-position: center;
      background-size: contain;
    }
    }
    @media only screen and (max-width: 768px) {
      height: auto;
      /* margin: 0 0 41px 0; */
      margin: auto;
      margin-bottom: 41px;
      margin-top: 0px;
      > p.main {
        font-size: 16px;
      }
      > p.detail {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 35px;
      }
      .analytic {
        margin: 40px 0 0 32px;
      }
      .design {
        margin: 40px 0 0 32px;
      }
      .coding {
        margin: 36px 0 0 32px;
    }
    .agile {
        margin: 40px 0 0 32px;
    }
    .growth {
        margin: 34px 0 0 32px;
    }
    .performance {
        margin: 36px 0 0 32px;
    }
    }
    @media only screen and (max-width: 425px) {
      width: 85%;
      margin-bottom: 30px;
      border-radius: 50px;
      > p.main {
        margin-top: 20px;
      }
      > p.detail {
        line-height: 20px;
        margin-bottom: 35px;
      }
      .analytic {
        margin: 30px 0 0 32px;
      }
      .design {
        margin: 30px 0 0 32px;
      }
    }
  }
`

export const HowWeWorkWhiteBox = styled.div`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 30px 60px #000127;
    opacity: 1;
    z-index: 1;
    margin: 0 0 41px 32px;
    > p.main {
      text-align: left;
      font-family: Raleway-Bold;
      font-size: 20px;
      letter-spacing: 0;
      color: #000127;
      margin-left: 32px;
      margin-top: 35px;
      margin-bottom: 0px;
      opacity: 1;
    }
    > p.detail {
      text-align: left;
      font-family: Poppins-Regular;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 25px;
      color: #000127;
      margin: 16px 32px;
      opacity: 1;
    }
    .analytic {
      width: 49px;
      height: 49px;
      margin: 84px 0 0 32px;
      background: transparent url(${analytics}) no-repeat padding-box;
      background-position: center;
      background-size: contain;
      opacity: 1;
    }
    .design {
      width: 49px;
      height: 49px;
      margin: 84px 0 0 32px;
      background: transparent url(${graphic}) no-repeat padding-box;
      background-position: center;
      background-size: contain;
      opacity: 1;
    }
    .coding {
      width: 53px;
      height: 53px;
      margin: 80px 0 0 32px;
      background: transparent url(${coding}) no-repeat padding-box;
      background-position: center;
      background-size: contain;
      opacity: 1;
    }
    .agile {
      width: 63px;
      height: 49px;
      margin: 84px 0 0 32px;
      background: transparent url(${agile}) no-repeat padding-box;
      background-position: center;
      background-size: contain;
      opacity: 1;
    }
    .growth {
      width: 55px;
      height: 55px;
      margin: 78px 0 0 32px;
      background: transparent url(${growth}) no-repeat padding-box;
      background-position: center;
      background-size: contain;
      opacity: 1;
    }
    .performance {
      width: 53px;
      height: 53px;
      margin: 80px 0 0 32px;
      background: transparent url(${performance}) no-repeat padding-box;
      background-position: center;
      background-size: contain;
      opacity: 1;
    }
    .slick-active &&& {
      background: transparent linear-gradient(0deg, #0028D2 0%, #3FF6CC 100%) 0% 0% no-repeat padding-box;
      > p.main {
        color: #FFFFFF;
      }
      > p.detail {
        color: #FFFFFF;
      }
      .analytic {
        background: transparent url(${analyticsWhite}) no-repeat padding-box;
        background-position: center;
        background-size: contain;
      }
      .design {
        background: transparent url(${graphicWhite}) no-repeat padding-box;
        background-position: center;
        background-size: contain;
      }
      .coding {
      background: transparent url(${codingWhite}) no-repeat padding-box;
      background-position: center;
      background-size: contain;
    }
    .agile {
      background: transparent url(${agileWhite}) no-repeat padding-box;
      background-position: center;
      background-size: contain;
    }
    .growth {
      background: transparent url(${growthWhite}) no-repeat padding-box;
      background-position: center;
      background-size: contain;
    }
    .performance {
      background: transparent url(${performanceWhite}) no-repeat padding-box;
      background-position: center;
      background-size: contain;
    }
    
  }
    @media only screen and (max-width: 768px) {
      height: auto;
      /* margin: 0 0 41px 0; */
      margin: auto;
      margin-bottom: 41px;
      margin-top: 0px;
      > p.main {
        font-size: 16px;
      }
      > p.detail {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 35px;
      }
      .analytic {
        margin: 40px 0 0 32px;
      }
      .design {
        margin: 40px 0 0 32px;
      }
      .coding {
        margin: 36px 0 0 32px;
    }
    .agile {
        margin: 40px 0 0 32px;
    }
    .growth {
        margin: 34px 0 0 32px;
    }
    .performance {
        margin: 36px 0 0 32px;
    }
    }
    @media only screen and (max-width: 425px) {
      min-height: 310px;
      width: calc(100% - 18px);
      margin: 0 0 0 16px;
      padding: 1px;
      > p.main {
        margin-top: 16px;
        margin-left: 15px;
      }
      > p.detail {
        margin: 8px 15px;
      }
      .analytic {
        margin: 20px 0 0 15px;
      }
      .design {
        margin: 20px 0 0 15px;
      }
      .coding {
        margin: 16px 0 0 15px;
    }
    .agile {
        margin: 20px 0 0 15px;
    }
    .growth {
        margin: 14px 0 0 15px;
    }
    .performance {
        margin: 16px 0 0 15px;
    }
    }
    @media only screen and (max-width: 320px) {
      min-height: 350px;
    }
`
// end how we work //

// the team //
export const TheTeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  @media only screen and (max-width: 1440px) {
    align-items: center;
  }
`

export const TheTeamBGContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 0;
  overflow: visible;
  .the-team-bg {
    position: relative;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 733px;
    margin-top: 305px;
    background: transparent url(${theTeam}) no-repeat padding-box;
    opacity: 1;
    background-position: 116% 0%;
    @media only screen and (max-width: 1800px) {
      opacity: 0.2;
    }
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
`

export const TheTeamContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 118px 0 0 138px;
  width: 970px;
  > p {
    position: relative;
    text-align: center;
    font-size: 26px;
    font-family: Raleway-Bold;
    line-height: 30px;
    margin: 32px 0 0 0;
    letter-spacing: 3.9px;
    color: #FFFFFF;
    opacity: 1;
    text-align: left;
    @media only screen and (max-width: 768px) {
      font-size: 20px;
      line-height: 26px;
    }
    @media only screen and (max-width: 425px) {
      margin: 16px 0 0 0;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.5px;
    }
  }
  > p.sub-header {
    position: relative;
    width: 750px;
    text-align: center;
    font-size: 22px;
    font-family: Raleway-Regular;
    line-height: 30px;
    margin: 30px 0 0 0;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
    text-align: left;
    @media only screen and (max-width: 768px) {
      width: 85%;
      font-size: 18px;
    }
    @media only screen and (max-width: 425px) {
      line-height: 22px;
      margin: 24px 0 0 0;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: center;
      width: 100%;
    }
  }
  @media only screen and (max-width: 1440px) {
    margin-left: 0;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    align-items: center;
    > p.sub-header {
      text-align: center;
    }
  }
  @media only screen and (max-width: 768px) {
    margin-top: 68px;
  }
  @media only screen and (max-width: 425px) {
    width: calc(100% - 48px);
    margin-top: 16px;
  }
`

export const TheTeamBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 107px 0 0 -56px;
  flex-wrap: wrap;
  .the-team {
    width: 425px;
    height: 215px;
    border: 2px solid #3CD2AF40;
    border-radius: 4px;
    opacity: 1;
    z-index: 1;
    margin: 0 0 56px 56px;
    > p.main {
      text-align: left;
      font-family: Raleway-Bold;
      font-size: 24px;
      letter-spacing: 0;
      color: #FFFFFF;
      margin-top: 35px;
      margin-left: 41px;
      margin-bottom: 0px;
      opacity: 1;
    }
    > p.detail {
      text-align: left;
      font-family: Raleway-Regular;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 22px;
      color: #FFFFFF;
      margin: 17px 41px 27px 41px;
      opacity: 1;
    }
    &:hover {
      background: #3CD2AF15 0% 0% no-repeat padding-box;
      border: 2px solid #3CD2AF38;
      > p.main {
        color: #3CD2AF;
      }
    }
  }
  @media only screen and (max-width: 1440px) {
    justify-content: center;
    margin-left: 0;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 60px;
    .the-team {
      width: 325px;
      height: auto;
      margin: auto;
      margin-top: 0;
      margin-bottom: 40px;
      > p.main {
        font-size: 18px;
        line-height: 22px;
      }
      > p.detail {
        font-size: 14px;
        line-height: 20px;
      }
      @media only screen and (max-width: 425px) {
        width: auto;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  @media only screen and (max-width: 425px) {
    margin-top: 40px;
      .the-team {
        margin-bottom: 16px;
        width: 100%;
        /* min-height: 135px; */
        min-height: auto;
        > p.main {
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        margin: 20px 0 0 19px;
        }
        > p.detail {
        font-size: 12px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        margin: 8px 19px 18px;
        }
      }
  }
`
// end the team //


// address //
export const screenSize = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
}

export const AddressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 80;
  margin-top: 200px;
  padding-top: 20px;
  padding-bottom: 30px;
  background: transparent linear-gradient(90deg, rgba(0,1,39,0.1) 0%, rgba(0,40,210,0.1) 13%, rgba(0,40,210,0.1) 87%, rgba(0,1,39,0.1) 100%) 0% 0% no-repeat padding-box;
  > a {
    > img {
      width: 728px;
    }
  }
  @media only screen and (max-width: 1440px) {
    > a {
      width: 50%;
      > img {
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    margin-top: 100px;
  }
  @media only screen and (max-width: 768px) {
    /* background-color: black; */
    flex-direction: column;
    margin-top: 75px;
    > a {
      width: auto;
      > img {
        width: 95%;
        margin-bottom: 16px;
      }
    }
  }
  @media only screen and (max-width: 425px) {
    margin-top: 50px;
    background-image: linear-gradient(180deg, #000127, #000539);
  }
`

export const StartSectionAddress = styled.div`
  display: none;
  position: relative;
  width: 47px;
  height: 4px;
  background: transparent linear-gradient(271deg, #0144FF 0%, #3FF6CC 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: 75px;
  @media only screen and (max-width: 768px) {
    margin-top: 40px;
  }
  @media only screen and (max-width: 425px) {
    display: block;
    margin-top: 0;
  }
`

export const AddressHeader = styled.p`
  display: none;
@media only screen and (max-width: 425px) {
  display: block;
  font-family: Raleway-Bold;
  color: #FFFFFF;
  margin: 16px 0 32px;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.5px;
}
`

export const DetailWithIconWrapper = styled.div`
  font-family: Raleway-Regular;
  font-size: 18px;
  line-height: 24px;
  margin-left: 244px;
  color: #FFFFFF;
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 12.58px;
    > p {
      font-family: Raleway-SemiBold;
      font-size: 24px;
      margin: 0;
    }
    > img {
      width: 40px;
      margin-right: 46.88px;
    }
    @media only screen and (max-width: 768px) {
      margin-bottom: 8px;
      > p {
        font-size: 18px;
      }
      > img {
        margin-right: 16px;
      }
    }
  }
  > p {
    margin: 0 0 0 86.88px;
    &.section-2 {
      margin: 12.45px 0 0 86.88px;
    }
    @media only screen and (max-width: 768px) {
      margin: 0 0 0 35px;
      &.section-2 {
        margin: 6px 0 0 35px;
      }
    }
    @media only screen and (max-width: 425px) {
          font-family: Raleway-Light;
          font-size: 12px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
        }
  }
  ${props => props.pin && css`
    .title {
      > img {
        width: 32.45px;
        margin-left: 7px;
        @media only screen and (max-width: 768px) {
          width: 16px;
          margin-left: 5px;
        }
        @media only screen and (max-width: 425px) {
          margin-left: 2px;
        }
      }
    }
  `}
  ${props => props.phone && css`
    .title {
      > img {
        width: 40.12px;
        @media only screen and (max-width: 768px) {
          width: 20px;
        }
      }
    }
  `}
  ${props => props.email && css`
    .title {
      > img {
        width: 40.99px;
        @media only screen and (max-width: 768px) {
          width: 20px;
        }
      }
    }
  `}
  &:first-child {
    margin-bottom: 43.83px;
  }
  &:last-child {
    margin-top: 43.83px;
  }
  @media only screen and (max-width: 1440px) {
    margin-left: 122px;
  }
  @media only screen and (max-width: 1024px) {
    margin-left: 61px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    &:last-child {
      margin: 24px 0;
    }
    &:first-child {
      margin: 24px 0;
    }
  }
  @media only screen and (max-width: 425px) {
    .title {
      > p {
        font-family: Raleway-Bold;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
      }
    }
  }
`
// end address //

// footer //
export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 99;
  padding: 40px 60px;
  background: #0E0F31 0% 0% no-repeat padding-box;
  color: #FFFFFF;
  > img {
    width: 127px;
  }
  > p {
    font-family: Raleway-Medium;
    font-size: 18px;
    margin: 0;
  }
  @media only screen and (max-width: 768px) {
    padding: 30px 5%;
    > img {
      width: 100px;
    }
    > p {
    font-family: Raleway-Medium;
    font-size: 16px;
    margin: 0;
  }
  }
  @media only screen and (max-width: 425px) {
    padding: 16px 24px;
    > p {
      font-size: 12px;
    }
    > img {
      display: none;
    }
  }
`

export const SocialMedia = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  > img {
    margin: 0 40px;
    &:last-child {
      margin: 0;
    }
    &:first-child {
      margin: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    > img {
      margin: 0 20px;
    }
  }
  @media only screen and (max-width: 425px) {
    > img {
      height: 16px;
      margin: 0 16px;
    }
  }
`
// end footer //

const multipleBoxShadow = (n) => {
  let value = []
  for (let i = 0; i < n; i += 1) {
    value.push(`${Math.floor(Math.random() * 2000)}px ${Math.floor(Math.random() * 1500)}px #FFF`)
  }
  value = value.join(', ')
  return (value)
}

export const Stars = styled.div`
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: ${multipleBoxShadow(300)};
  animation: animStar 50s linear infinite;
  &:after {
    content: " ";
    position: absolute;
    top: 1500px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: ${multipleBoxShadow(100)};
  }
`
export const Stars2 = styled.div`
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: ${multipleBoxShadow(80)};
  animation: animStar 100s linear infinite;
  &:after {
    content: " ";
    position: absolute;
    top: 1500px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: ${multipleBoxShadow(80)};
  }
`

export const Stars3 = styled.div`
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: ${multipleBoxShadow(40)};
  animation: animStar 150s linear infinite;
  &:after {
    content: " ";
    position: absolute;
    top: 1500px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: ${multipleBoxShadow(40)};
  }
`

export const MenuAnim = styled.div`
  &:before,
  &:after {
    content: '';
    position: absolute;
    background: var(--color);
  }
  
  &:focus:before,
  &:hover:before {
    animation: beforeBorders var(--animation-speed) forwards ease-in-out;
  }

  &:focus:after,
  &:hover:after {
    animation: afterBorders var(--animation-speed) forwards ease-in-out;
  }
  
  &:focus,
  &:hover {
    animation: borderColors var(--animation-speed) steps(1) forwards;
    outline: none;
  }
}

@keyframes beforeBorders {
  0% {
    top: calc(1px * -1);
    left: 50%;
    bottom: auto;
    right: auto;
    width: 0;
    height: 1px;
  }
  33% {    
    top: calc(1px * -1);
    left: calc(1px * -1);
    bottom: auto;
    right: auto;
    width: calc(1px + 50%);
    height: 1px;
  }
  66% {
    top: calc(1px * -1);
    left: calc(1px * -1);
    bottom: auto;
    right: auto;
    width: 1px;
    height: calc((1px * 2) + 100%);
  }
  100% {
    top: auto;
    left: calc(1px * -1);
    bottom: calc(1px * -1);
    right: auto;
    width: calc(1px + 50%);
    height: calc((1px * 2) + 100%);
  }
}

@keyframes afterBorders {
  0% {
    top: calc(1px * -1);
    left: auto;
    bottom: auto;
    right: 50%;
    width: 0;
    height: 1px;
  }
  33% {    
    top: calc(1px * -1);
    left: auto;
    bottom: auto;
    right: calc(1px * -1);
    width: calc(1px + 50%);
    height: 1px;
  }
  66% {
    top: calc(1px * -1);
    left: auto;
    bottom: auto;
    right: calc(1px * -1);
    width: 1px;
    height: calc((1px * 2) + 100%);
  }
  100% {
    top: auto;
    left: auto;
    bottom: calc(1px * -1);
    right: calc(1px * -1);
    width: calc(1px + 50%);
    height: calc((1px * 2) + 100%);
  }
}

@keyframes borderColors {
  0% {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  33% {
    border-top-color: rgba(255, 255, 255, 1);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;

  }
  66% {
    border-top-color: rgba(255, 255, 255, 1);
    border-right-color: rgba(255, 255, 255, 1);
    border-bottom-color: transparent;
    border-left-color: rgba(255, 255, 255, 1);
  }
  100% {
    border-top-color: rgba(255, 255, 255, 1);
    border-right-color: rgba(255, 255, 255, 1);
    border-bottom-color: rgba(255, 255, 255, 1);
    border-left-color: rgba(255, 255, 255, 1);
  }
}

@keyframes background {
  to {
    background: #555;
    text-shadow: 0 0.1em 0.1em #111;
  }
}

@media (min-width: 850px) {
  body {
    justify-content: center;
  }
}

@media (min-width: 1200px) {
  .border-animation {
    font-size: 6em;
    line-height: 1em;    
  }
}
`

export const MapContainer = styled.div`
  display: flex;
  marginTop: 24px;
  width: 100%
  @media only screen and (max-width: 1440px) {
    }
  @media only screen and (max-width: 768px) {
  }
  @media only screen and (max-width: 425px) {
    flex-direction: column
  }
  >div.map {
    width: 50%
    @media only screen and (max-width: 1440px) {
      }
    @media only screen and (max-width: 768px) {
    }
    @media only screen and (max-width: 425px) {
      width: 100%
      height: 400px
    } 
  }
`

export default Container
