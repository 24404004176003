import React, { useEffect, useRef, useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import BOXSUITMOBILE from '../assets/box.png'
import BOXSUIT from '../assets/boxsuit.png'
import BWIM from '../assets/BWIM Mockup.png'
import IMPACT from '../assets/impact.png'
import JUTIVERSE from '../assets/jutiverse.png'
import LOGPLUS from '../assets/logplus.png'
import METINEEMOBILE from '../assets/metinee.png'
import METINEESECRET from '../assets/metineesecret.png'
import PADOONGRICH from '../assets/padoongrich.png'
import SCB from '../assets/SCBWealthMockups.png'
import SCG from '../assets/scg.png'
import SPRINGME from '../assets/springme.png'
import TASKINTERIOR from '../assets/task-interior.png'
import TEWSMOBILE from '../assets/TewsBarber.png'
import TEWSBARBER from '../assets/tewsbarbershop.png'
import UPPARTS from '../assets/upparts.png'
import UPPARTSMOBILE from '../assets/uppartsMobile.png'
import '../components/project.css'
import {
  OurProjectsContainer, ProjectButton, ProjectColumn, ProjectContentContainer, StartSectionOurProjects,
} from '../components/styled'

const slideImages = [
  { src: BOXSUITMOBILE },
  { src: SCG },
  { src: SCB },
  { src: TASKINTERIOR },
  { src: IMPACT },
  { src: JUTIVERSE },
  { src: TEWSBARBER },
  { src: LOGPLUS },
  { src: PADOONGRICH },
  { src: SPRINGME },
  { src: UPPARTSMOBILE },
  { src: METINEEMOBILE },
  { src: TEWSMOBILE },
  { src: BWIM },
]

export const OurProjects = ({ myRef }) => {
  const [showAll, setShowAll] = useState(false)
  const [images, setImage] = useState(slideImages)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [mobileModalIsOpen, setMobileModalIsOpen] = useState(false)
  const [index, setIndex] = useState(0)
  const ref = useRef()
  let firstClientX
  let clientX

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    centerMode: true,
    touchThreshold: 10,
    // centerPadding: '60px',
    slidesToShow: 1,
    // lazyLoad: true,
    afterChange: (e) => {
      setIndex(e)
    },
    // slickGoTo: index,
  }

  const preventTouch = (e) => {
    const minValue = 5 // threshold

    clientX = e.touches[0].clientX - firstClientX

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault()
      e.returnValue = false

      return false
    }
    return false
  }
  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX
  }
  const containerRef = useRef()

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('touchstart', touchStart)
      containerRef.current.addEventListener('touchmove', preventTouch, {
        passive: false,
      })
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('touchstart', touchStart)
        containerRef.current.removeEventListener('touchmove', preventTouch, {
          passive: false,
        })
      }
    }
  })
  return (
    <OurProjectsContainer>
      <StartSectionOurProjects />
      <p>OUR PROJECTS</p>
      <ProjectContentContainer showAll={showAll} className='desktop-only'>
        <ProjectColumn ref={ref}>
          <div className='box-suit' style={{ margin: '0 0 32px 0' }} onClick={() => {
            setModalIsOpen(!modalIsOpen)
            setImage([{ src: BOXSUIT }])
          }} />
          <div className='task-interior' style={{ margin: '0 0 32px 0' }} onClick={() => {
            setModalIsOpen(!modalIsOpen)
            setImage([{ src: TASKINTERIOR }])
          }} />
          <div className='impact' style={{ margin: '0 0 32px 0' }} onClick={() => {
            setModalIsOpen(!modalIsOpen)
            setImage([{ src: IMPACT }])
          }} />
          <div className='padoongrich' style={{ margin: '0 0 32px 0' }} onClick={() => {
            setModalIsOpen(!modalIsOpen)
            setImage([{ src: PADOONGRICH }])
          }} />
          <div className='logplus' style={{ margin: '0 0 32px 0' }} onClick={() => {
            setModalIsOpen(!modalIsOpen)
            setImage([{ src: LOGPLUS }])
          }} />
        </ProjectColumn>
        <ProjectColumn>
          <div className='scb' style={{ margin: '0 0 32px 0' }} onClick={() => {
            setModalIsOpen(!modalIsOpen)
            setImage([{ src: SCB }])
          }} />
          <div className='jutiverse' style={{ margin: '0 0 32px 0' }} onClick={() => {
            setModalIsOpen(!modalIsOpen)
            setImage([{ src: JUTIVERSE }])
          }} />
          <div className='scg' style={{ margin: '0 0 32px 0' }} onClick={() => {
            setModalIsOpen(!modalIsOpen)
            setImage([{ src: SCG }])
          }} />
          <div className='bwim' style={{ margin: '0 0 32px 0' }} onClick={() => {
            setModalIsOpen(!modalIsOpen)
            setImage([{ src: BWIM }])
          }} />
          <div className='tewsmobile' style={{ margin: '0 0 32px 0' }} onClick={() => {
            setModalIsOpen(!modalIsOpen)
            setImage([{ src: TEWSMOBILE }])
          }} />
        </ProjectColumn>
        <ProjectColumn>
          <div className='springme' style={{ margin: '0 0 32px 0' }} onClick={() => {
            setModalIsOpen(!modalIsOpen)
            setImage([{ src: SPRINGME }])
          }} />
          <div className='upparts' style={{ margin: '0 0 32px 0' }} onClick={() => {
            setModalIsOpen(!modalIsOpen)
            setImage([{ src: UPPARTS }])
          }} />
          <div className='metineesecret' style={{ margin: '0 0 32px 0' }} onClick={() => {
            setModalIsOpen(!modalIsOpen)
            setImage([{ src: METINEESECRET }])
          }} />
          <div className='tewsbarbershop' style={{ margin: '0 0 32px 0' }} onClick={() => {
            setModalIsOpen(!modalIsOpen)
            setImage([{ src: TEWSBARBER }])
          }} />
        </ProjectColumn>
      </ProjectContentContainer>
      {
        // ref.current !== undefined && ref.current.offsetHeight === ref.current.scrollHeight
        showAll
          ? <ProjectButton onClick={() => {
            setShowAll(false)
            window.scrollTo(0, myRef.ourProjectsRef.current.offsetTop + 783)
          }} className='desktop-only'>
            <p>
              Show less
            </p>
          </ProjectButton>
          : <ProjectButton onClick={() => setShowAll(true)} className='desktop-only'>
            <p>
              Load more
          </p>
          </ProjectButton>
      }
      <div className='mobile-only' style={{ marginTop: '-16px' }} />
      <div className='tablet' style={{ marginTop: '32px', width: '100%' }} ref={containerRef}>
      <Slider {...settings}>
        <div>
          <div className='slider-image-frame' onClick={() => {
            setMobileModalIsOpen(!modalIsOpen)
          }}>
            <img src={BOXSUITMOBILE} alt='BOXSUITMOBILE' />
          </div>
        </div>
        <div>
          <div className='slider-image-frame' onClick={() => {
            setMobileModalIsOpen(!modalIsOpen)
          }}>
            <img src={SCG} alt='SCG' />
          </div>
        </div>
        <div>
          <div className='slider-image-frame' onClick={() => {
            setMobileModalIsOpen(!modalIsOpen)
          }}>
            <img src={SCB} alt='SCB' />
          </div>
        </div>
        <div>
          <div className='slider-image-frame' onClick={() => {
            setMobileModalIsOpen(!modalIsOpen)
          }}>
            <img src={TASKINTERIOR} alt='TASKINTERIOR' />
          </div>
        </div>
        <div>
          <div className='slider-image-frame' onClick={() => {
            setMobileModalIsOpen(!modalIsOpen)
          }}>
            <img src={IMPACT} alt='IMPACT' />
          </div>
        </div>
        <div>
          <div className='slider-image-frame' onClick={() => {
            setMobileModalIsOpen(!modalIsOpen)
          }}>
            <img src={JUTIVERSE} alt='JUTIVERSE' />
          </div>
        </div>
        <div>
          <div className='slider-image-frame' onClick={() => {
            setMobileModalIsOpen(!modalIsOpen)
          }}>
            <img src={TEWSBARBER} alt='TEWSBARBER' />
          </div>
        </div>
        <div>
          <div className='slider-image-frame' onClick={() => {
            setMobileModalIsOpen(!modalIsOpen)
          }}>
            <img src={LOGPLUS} alt='LOGPLUS' />
          </div>
        </div>
        <div>
          <div className='slider-image-frame' onClick={() => {
            setMobileModalIsOpen(!modalIsOpen)
          }}>
            <img src={PADOONGRICH} alt='PADOONGRICH' />
          </div>
        </div>
        <div>
          <div className='slider-image-frame' onClick={() => {
            setMobileModalIsOpen(!modalIsOpen)
          }}>
            <img src={SPRINGME} alt='SPRINGME' />
          </div>
        </div>
        <div>
          <div className='slider-image-frame' onClick={() => {
            setMobileModalIsOpen(!modalIsOpen)
          }}>
            <img src={UPPARTSMOBILE} alt='UPPARTSMOBILE' />
          </div>
        </div>
        <div>
          <div className='slider-image-frame' onClick={() => {
            setMobileModalIsOpen(!modalIsOpen)
          }}>
            <img src={METINEEMOBILE} alt='METINEEMOBILE' />
          </div>
        </div>
        <div>
          <div className='slider-image-frame' onClick={() => {
            setMobileModalIsOpen(!modalIsOpen)
          }}>
            <img src={TEWSMOBILE} alt='TEWSMOBILE' />
          </div>
        </div>
        <div>
          <div className='slider-image-frame' onClick={() => {
            setMobileModalIsOpen(!modalIsOpen)
          }}>
            <img src={BWIM} alt='BWIM' />
          </div>
        </div>
      </Slider>
      </div>
      <ModalGateway>
        {modalIsOpen ? (
          <Modal onClose={() => setModalIsOpen(!modalIsOpen)} allowFullscreen={false} styles={{
            positioner: base => ({
              ...base,
              // alignItems: 'flex-start',
              // overflow: 'auto',
            }),
            dialog: base => ({
              ...base,
              margin: 'auto',
            }),
          }}>
            <Carousel views={images} isFullscreen={false} components={{ Footer: null }} styles={{
              container: base => ({
                ...base,
              }),
              view: base => ({
                ...base,
                height: '100vh',
                display: 'flex ',
                justifyContent: 'center',
                touchAction: 'pinch-zoom',
                '& > img': {
                  maxWidth: '45%',
                  margin: 'auto',
                  // maxHeight: '80%',
                },
              }),
            }} />
          </Modal>
        ) : null}
        {mobileModalIsOpen ? (
          <Modal onClose={() => setMobileModalIsOpen(!mobileModalIsOpen)} allowFullscreen={false} styles={{
            positioner: base => ({
              ...base,
              alignItems: 'flex-start',
            }),
            dialog: base => ({
              ...base,
              margin: 'auto',
            }),
          }}>
            <Carousel views={slideImages} isFullscreen={false} currentIndex={index} components={{ Footer: null }} styles={{
              container: base => ({
                ...base,
              }),
              view: base => ({
                ...base,
                // display: 'flex ',
                // justifyContent: 'center',
                '& > img': {
                  maxWidth: '100%',
                  // height: 'auto',
                  maxHeight: '90vh',
                },
              }),
            }} />
          </Modal>
        ) : null}
      </ModalGateway>
    </OurProjectsContainer >
  )
}

export default OurProjects
