import React from 'react'
import styled from 'styled-components'
import { EndSection, ChallengeContainer } from './styled'

const GradientBordered = styled.div`
  background: #031756;
  padding: 48px 95px;
  border: 1px solid rgba(13, 100, 247, 0.5);
  border-radius: 8px;
  width: 548px;
  margin: ${props => (!props.isLast ? '0 24px 0 0' : 0)}
  @media only screen and (max-width: 960px) {
    width: 80vw;
    height: 100%;
    padding: 28px;
    margin: ${props => (!props.isLast ? '0 0 24px 0' : 0)}
  }
`

export const ChallengeResult = () => (
  <ChallengeContainer>
    <GradientBordered>
      <EndSection style={{ margin: 0 }} />
      <p className='header'>CHALLENGE</p>
      <ul style={{ padding: 0, margin: 0 }}>
        <li style={{ color: '#FFFFFF' }}>
          <p className='detail'>Coordination problem between the operations team and drivers</p>
        </li>
        <li style={{ color: '#FFFFFF' }}>
          <p className='detail'>They operate on installed, on-premise software and communicated mainly with papers</p>
        </li>
        <li style={{ color: '#FFFFFF' }}>
          <p className='detail'>Data is fragmented, unreliable and delayed.</p>
        </li>
      </ul>
    </GradientBordered>
    <GradientBordered isLast={true}>
      <EndSection style={{ margin: 0 }} />
      <p className='header'>RESULT</p>
      <ul style={{ padding: 0, margin: 0 }}>
        <li style={{ color: '#FFFFFF' }}>
          <p className='detail'>Implemented various on-cloud web applications to coordinate internally and streamlined the data to Power BI to view insights in real-time</p>
        </li>
        <li style={{ color: '#FFFFFF' }}>
          <p className='detail'>Workflow is now standardized, digitized and very efficient.</p>
        </li>
        <li style={{ color: '#FFFFFF' }}>
          <p className='detail'>The company now maintain a reliable and real-time data in a single data center</p>
        </li>
      </ul>
    </GradientBordered>
  </ChallengeContainer>
)

export default ChallengeResult
