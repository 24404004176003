import styled, { css } from 'styled-components'
import StacksBackground from '../../assets/padoongrich/BG-01.png'
import artWork from '../../assets/padoongrich/Img-01.png'

export const TransparentButton = styled.button`
  background: transparent;
  border: 1px solid #B2C5D3;
  border-radius: 54px;
  color: #B2C5D3;
  width: 160px;
  font-size: 16px;
  font-family: raleway;
  font-weight: 600;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  cursor: pointer;
`

export const Artwork = styled.div`
  position: relative;
  width: 87%;
  margin-top: 25px;
  /* background: transparent url(${artWork}) 0% 0% no-repeat padding-box; */
  background-position: center;
  background-size: cover;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: visible;
  @media only screen and (max-width: 1440px) {
    background: none;
    height: auto;
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
  margin: 5px 0 0 0;
  }
  > img {
    width: 100%;
    display: none;
    @media only screen and (max-width: 1440px) {
    display: flex;
    }
    @media only screen and (max-width: 768px) {
      width: 120%;
    }
    @media only screen and (max-width: 425px) {
      display: none;
    }
  }
  > img.center {
    width: 140%;
    margin-top: -40px;
    display: none;
    @media only screen and (max-width: 425px) {
      display: flex;
      width: 165%;
    }
  }
`

export const FiveBox = styled.div`
  flex: 1;
  border-right: 1px solid rgba(13, 100, 247, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  min-width: 120px;
  > img {
    width: 60px;
    @media only screen and (max-width: 1440px) {
      width: 40px;
    }
    /* @media only screen and (max-width: 768px) {
      width: 30px;
    } */
  }
  > p {
    margin: 0;
    position: relative;
    text-align: center;
    font-size: 18px;
    font-family: Raleway-Regular;
    margin: 8px 0 0 0;
    color: #FFFFFF;
    opacity: 1;
    @media only screen and (max-width: 1440px) {
      font-size: 14px;
    }
    /* @media only screen and (max-width: 768px) {
      font-size: 10px;
    } */
  }
`

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-image: url(${artWork});
  background-position: center;
  background-size: cover;
  height: calc(100vh - 203px);
  @media only screen and (max-width: 768px) {
    padding-top: 24px;
  }
  > h1 {
    position: relative;
    /* white-space: nowrap; */
    width: auto;
    text-align: center;
    font-size: 120px;
    font-family: Circular;
    line-height: 152px;
    font-style: Black;
    margin: 35px 0 0 0;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
    @media only screen and (max-width: 1440px) {
      font-size: 90px;
      line-height: 100px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 60px;
      line-height: 70px;
    }
    @media only screen and (max-width: 425px) {
      /* font-size: 60px; */
      /* line-height: 1; */
      font-size: 13vw;
      line-height: 13vw;
      margin: 0;
    }
  }
  > p {
    position: relative;
    width: 42%;
    text-align: center;
    font-size: 26px;
    font-family: Raleway-Regular;
    line-height: 30px;
    margin: 23px 0 0 0;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
    @media only screen and (max-width: 1440px) {
      font-size: 20px;
      line-height: 26px;
      width: 80%;
    }
    @media only screen and (max-width: 425px) {
      font-family: Raleway-Medium;
      /* font-size: 16px; */
      font-size: 20px;
      margin: 16px 0 0 0;
      width: auto;
      padding: 0 24px;
    }
  }
  > img {
    display: none;
    width: 100%;
    @media only screen and (max-width: 1440px) {
      display: block;
    }
  }
`

export const StartButton = styled.div`
  position: relative;
  /* width: 184px; */
  height: 48px;
  padding: 0 16px;
  cursor: pointer;
  background: transparent linear-gradient(329deg, #0144FF 0%, #3FF6CC 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 23px 26px #0001274B;
  border-radius: 50px;
  margin: 34px 0 0 0;
  opacity: 1;
  transition: all 0.3s;
  z-index: 97;
  @media only screen and (max-width: 425px) {
  /* margin: 30px 0 0 0; */
  width: 160px;
  height: 40px;
  }
  > p{
    position: relative;
    top: 14px;
    width: 100%;
    height: 21px;
    text-align: center;
    white-space: nowrap;
    font-size: 19px;
    font-family: Raleway-SemiBold;
    line-height: 21px;
    letter-spacing: 0;
    margin: 0;
    color: #FFFFFF;
    text-transform: capitalize;
    opacity: 1;
    @media only screen and (max-width: 425px) {
      font-size: 16px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%)
    }
  }
  &:hover {
    background: transparent linear-gradient(329deg, rgb(1,68,255, 0.9) 0%, rgb(63,246,204, 0.9) 100%) 0% 0% no-repeat padding-box;
    > p {
      color: rgb(255,255,255, 0.9);
    }
  }
`

export const BenefitBG = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 32px 0;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const BenefitContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  padding: 24px 32px;
  @media only screen and (max-width: 768px) {
    width: 80vw;
  }
  > p.header {
    text-align: left;
    font-size: 40px;
    white-space: nowrap;
    font-family: Raleway;
    font-weight: 700;
    line-height: 16px;
    margin: 24px 0 0 0;
    letter-spacing: 1%;
    color: #FFFFFF;
    opacity: 1;
    @media only screen and (max-width: 1440px) {
      font-size: 25px;
    }
  }
  > p.semi-header {
    text-align: left;
    font-size: 40px;
    white-space: nowrap;
    font-family: Raleway;
    font-weight: 200;
    line-height: 8px;
    margin: 32px 0 0 0;
    letter-spacing: 1%;
    color: #FFFFFF;
    opacity: 1;
    @media only screen and (max-width: 1440px) {
      font-size: 25px;
    }
  }
  > p.detail {
    position: relative;
    text-align: left;
    font-size: 19px;
    color: #3CD2AF;
    font-family: Raleway;
    font-weight: 500;
    @media only screen and (max-width: 1440px) {
      font-size: 14px;
    }
  }
`

export const SideImage = styled.img`
  width: 50%;
  object-fit: contain;
  @media only screen and (max-width: 767px) {
    width: calc(100% - 16px);
    align-self: ${props => (props.align === 'right' ? 'flex-end' : 'flex-start')};
  }
`

export const BenefitDetailRowRender = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
  margin: 40px 0 0 0;
  color: #FFFFFF;
  font-family: Raleway;
  p {
    font-family: Raleway;
    font-weight: 500;
    font-size: 19px;
    @media only screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    width: auto;
    margin-top: 0px;
  }
  @media only screen and (max-width: 425px) {
    width: calc(100% - 48px);
  }
`

export const StartSectionBenefit = styled.div`
  position: relative;
  width: 47px;
  height: 4px;
  background: transparent linear-gradient(271deg, #0144FF 0%, #3FF6CC 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: 75px;
  @media only screen and (max-width: 768px) {
    margin-top: 40px;
  }
  @media only screen and (max-width: 425px) {
    margin-top: 75px;
  }
`

export const SectionBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 80%;
  padding: 80px 96px;
  margin: -130px auto 0;
  border-radius: 32px;
  background: linear-gradient(180deg, rgba(3, 18, 69, 0) 0%, rgba(2, 10, 55, 100) 100%);
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
  > .block {
    width: 50%;
    @media only screen and (max-width: 768px) {
      width: 80vw;
    }
    > p.header {
      text-align: left;
      font-size: 40px;
      white-space: nowrap;
      font-family: Raleway;
      font-weight: 700;
      line-height: 8px;
      margin: 40px 0 0 0;
      letter-spacing: 1%;
      color: #FFFFFF;
      @media only screen and (max-width: 1440px) {
        font-size: 35px;
      }
    }
    > p.detail {
      position: relative;
      text-align: left;
      font-size: 19px;
      color: #FFFFFF;
      font-family: raleway;
      font-weight: 500;
      @media only screen and (max-width: 1440px) {
        font-size: 14px;
        width: 85%;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
    > p.detail.bullet {
      font-weight: 700;
      display: flex;
      align-items: center;
    }
  }
`

export const Stack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  margin: -75px 0 0 0;
  max-width: 80vw;
  /* height: 180px; */
  opacity: 1;
  z-index: 0;
  padding: 32px 40px;

  background: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(15px);
  border-radius: 8px;
  border: 1px solid #3CD2AF40;
  > p {
    font-family: raleway;
    font-weight: 700;
    color: #FFFFFF;
    font-size: 20px;
    letter-spacing: 3px;
  }
  > div {
    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
  }
  @media only screen and (max-width: 768px) {
    border-radius: 8px;
    height: auto;
  }
`

export const StackShadow = styled.div`
  display: none;
  @media only screen and (max-width: 425px) {
    /* padding: 0 15px 10px 15px; */
    display: flex;
    margin-top: -12px;
    border-radius: 6px;
    width: calc(100% - 48px);
    background-image: linear-gradient(180deg, #22a3e4 50%, #39e6d0);
    height: 19px;
  }
`

export const StacksSection = styled.div`
  background: transparent url(${StacksBackground});
  width: 100%;
  background-size: cover;
  padding-bottom: 40px;
`

export const StartSection = styled.div`
  position: relative;
  width: 47px;
  height: 4px;
  background: transparent linear-gradient(271deg, #0144FF 0%, #3FF6CC 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
`

export const TestimonialWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 120px auto 40px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin: 120px auto 0;
  }
`

export const TestimonialCard = styled.div`
  width: 455px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #3CD2AF40;
  border-radius: 16px;
  padding: 88px 48px;
  backdrop-filter: blur(15px);
  margin: 0 12px;
  position: relative;
  > img {
    width: 88px;
    border: 1px solid #3CD2AF;
    border-radius: 50%;
    position: absolute;
    top: -44px;
    z-index: 1;
  }
  > img.circle-bg {
    width: 142px;
    top: -71px;
    border: none;
  }
  > p {
    font-size: 19px;
    color: #FFFFFF;
    font-family: Raleway;
    font-weight: 500;
    text-align: start;
  }
  > p.title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin: 4px;
  }

  @media only screen and (max-width: 768px) {
    width: 70vw;
    padding: 64px 24px;
    margin: 0 12px 80px 0;
    > img {
      width: 72px;
      border: 1px solid #3CD2AF;
      border-radius: 50%;
      position: absolute;
      top: -36px;
      z-index: 1;
    }
    > img.circle-bg {
      width: 118px;
      top: -59px;
      border: none;
    }
    > p {
      font-size: 14px;
      color: #FFFFFF;
      font-family: Raleway;
      font-weight: 500;
      text-align: start;
    }
    > p.title {
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      margin: 4px;
    }
  }
`

export const EndSection = styled.div`
  position: relative;
  width: 47px;
  height: 4px;
  background: transparent linear-gradient(271deg, #0144FF 0%, #3FF6CC 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  margin: -37px 0 0 0;
  @media only screen and (max-width: 1440px) {
    margin: 20px 0 0 0;
  }
  @media only screen and (max-width: 425px) {
    margin: 52px 0 0 0;
  }
`

export const Section = styled.div`
  max-width: 1800px;
  margin: auto;
  flex-wrap: wrap;
`

export const BlurBlock = styled.div`
  width: 100vw;
  /* max-height: 121px; */
  border-bottom: 1px solid rgba(13, 100, 247, 0.5);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(10px);
  background: linear-gradient(180deg, rgba(2, 27, 86, 0.7) 0%, rgba(1, 12, 58, 0.7) 237.96%);
  overflow-x: auto;
`

export const ChallengeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  padding: 40px 88px;
  background: #021245;
  @media only screen and (max-width: 960px) {
    flex-direction: column;
    align-items: center;
  }
  > div {
    > p.header {
      position: relative;
      width: auto;
      font-size: 26px;
      white-space: nowrap;
      line-height: 30px;
      color: #FFFFFF;
      opacity: 1;
      margin-top: 8px;
      margin-bottom: 48px;
      font-family: Raleway-Bold;
      letter-spacing: 3.9px;
      @media only screen and (max-width: 1440px) {
        font-size: 20px;
        margin-bottom: 16px;
      }
      @media only screen and (max-width: 425px) {
        margin: 16px 0 0 0;
        font-size: 16px;
        letter-spacing: 1.5px;
      }
    }
    > p.motto {
      position: relative;
      width: 36%;
      text-align: center;
      font-size: 32px;
      line-height: 46px;
      color: #FFFFFF;
      opacity: 1;
      margin: 58px 0 0 0;
      font-family: Raleway-Bold;
      letter-spacing: 0;
      @media only screen and (max-width: 1440px) {
        font-size: 24px;
        line-height: 34px;
        width: 80%;
        margin-top: 30px;
      }
      @media only screen and (max-width: 425px) {
        margin: 24px 0 0 0;
        font-size: 19px;
        line-height: normal;
        letter-spacing: normal;
        font-stretch: normal;
        font-style: normal;
        width: auto;
        padding: 0 24px;
      }
    }
    p.detail {
      position: relative;
      font-size: 19px;
      line-height: 32px;
      color: #FFFFFF;
      opacity: 1;
      margin: 0;
      font-family: Raleway-Medium;
      letter-spacing: 0;
      @media only screen and (max-width: 1440px) {
        font-size: 16px;
      }
      @media only screen and (max-width: 425px) {
        font-size: 14px;
      }
    }
  }
`
export const StackImage = styled.img`
  width: 16%;
  @media only screen and (max-width: 768px) {
    width: 40%;
  }
`


export const AddressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 80;
  padding-top: 20px;
  padding-bottom: 30px;
  /* background: transparent linear-gradient(90deg, rgba(0,1,39,0.1) 0%, rgba(0,40,210,0.1) 13%, rgba(0,40,210,0.1) 87%, rgba(0,1,39,0.1) 100%) 0% 0% no-repeat padding-box; */
  > a {
    > img {
      width: 728px;
    }
  }
  @media only screen and (max-width: 1440px) {
    > a {
      width: 60%;
      > img {
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    /* margin-top: 100px; */
  }
  @media only screen and (max-width: 768px) {
    /* background-color: black; */
    flex-direction: column;
    /* margin-top: 75px; */
    > a {
      width: auto;
      > img {
        width: 95%;
        margin-bottom: 16px;
      }
    }
  }
  @media only screen and (max-width: 425px) {
    margin-top: -100px;
    background-image: linear-gradient(180deg, #000127, #000539);
  }
`

export const StartSectionAddress = styled.div`
  display: none;
  position: relative;
  width: 47px;
  height: 4px;
  background: transparent linear-gradient(271deg, #0144FF 0%, #3FF6CC 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: 75px;
  @media only screen and (max-width: 768px) {
    margin-top: 40px;
  }
  @media only screen and (max-width: 425px) {
    display: block;
    margin-top: 0;
  }
`

export const DetailWithIconWrapper = styled.div`
  font-family: Raleway-Regular;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 12.58px;
    p {
      font-family: Raleway-SemiBold;
      font-size: 24px;
      margin: 0;
      color: #FFFFFF;
    }
    img {
      width: 40px;
      margin-right: 46.88px;
    }
    @media only screen and (max-width: 768px) {
      margin-bottom: 8px;
      p {
        font-size: 18px;
      }
      > img {
        margin-right: 16px;
      }
    }
  }
  p {
    margin: 0 0 0 86.88px;
    color: #FFFFFF;
    &.section-2 {
      margin: 12.45px 0 0 86.88px;
    }
    @media only screen and (max-width: 768px) {
      margin: 0 0 0 35px;
      &.section-2 {
        margin: 6px 0 0 35px;
      }
    }
    @media only screen and (max-width: 425px) {
          font-family: Raleway-Light;
          font-size: 12px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
        }
  }
  ${props => props.pin && css`
    .title {
      > img {
        width: 32.45px;
        margin-left: 7px;
        @media only screen and (max-width: 768px) {
          width: 16px;
          margin-left: 5px;
        }
        @media only screen and (max-width: 425px) {
          margin-left: 2px;
        }
      }
    }
  `}
  ${props => props.phone && css`
    .title {
      > img {
        width: 40.12px;
        @media only screen and (max-width: 768px) {
          width: 20px;
        }
      }
    }
  `}
  ${props => props.email && css`
    .title {
      > img {
        width: 40.99px;
        @media only screen and (max-width: 768px) {
          width: 20px;
        }
      }
    }
  `}
  &:first-child {
    margin-bottom: 43.83px;
  }
  &:last-child {
    margin-top: 43.83px;
  }
  @media only screen and (max-width: 1440px) {
    /* margin-left: 60px; */
    margin: auto;
  }
  @media only screen and (max-width: 1024px) {
    margin-left: 61px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    &:last-child {
      margin: 24px 0;
    }
    &:first-child {
      margin: 24px 0;
    }
  }
  @media only screen and (max-width: 425px) {
    .title {
      p {
        font-family: Raleway-Bold;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
      }
    }
  }
`
