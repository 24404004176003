import React from 'react'
import {
  FooterContainer, SocialMedia,
} from '../components/styled'
import Logo from '../assets/Image 5.png'
import Facebook from '../assets/Facebook.svg'
import Twitter from '../assets/Twitter.svg'
import LinkIn from '../assets/LinkIn.svg'

export const Footer = () => (
  <FooterContainer>
    <p>© 2020 by Konsys</p>
    <img src={Logo} alt="" />
    <SocialMedia>
      <img src={Facebook} alt='' />
      <img src={Twitter} alt='' />
      <img src={LinkIn} alt='' />
    </SocialMedia>
  </FooterContainer>
)

export default Footer
