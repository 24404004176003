import React from 'react'
import {
  Section,
  Stack, StackImage, StackShadow, StacksSection, StartButton, StartSection, TestimonialCard, TestimonialWrapper,
} from './styled'
import react from '../../assets/padoongrich/react_white.png'
import node from '../../assets/padoongrich/node_white.png'
import kubernate from '../../assets/padoongrich/kubernate_white.png'
import docker from '../../assets/padoongrich/docker_white.png'
import googleCloud from '../../assets/padoongrich/google_cloud_white.png'
import graphql from '../../assets/padoongrich/graphql_white.png'
import testimonial from '../../assets/padoongrich/testimonial.png'
import circle from '../../assets/padoongrich/testimonial-bg-circle.png'
import testimonial1 from '../../assets/padoongrich/IMG_5015.jpg'
import testimonial2 from '../../assets/padoongrich/IMG_5016.jpeg'

export const Stacks = ({ setVisible }) => (
  <StacksSection>
    <Section>
      <div style={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%',
      }}>
        <Stack>
          <StartSection />
          <p>OUR TECHNOLOGY</p>
          <div style={{
            display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center',
          }}>
            <StackImage src={react} alt='react' />
            <StackImage src={node} alt='node' />
            <StackImage src={kubernate} alt='kubernate' />
            <StackImage src={docker} alt='docker' />
            <StackImage src={graphql} alt='graphql' />
            <StackImage src={googleCloud} alt='google_cloud' />
          </div>
        </Stack>
        <StackShadow />
      </div>
      <TestimonialWrapper>
        <TestimonialCard>
          <img src={circle} alt='testimonial' className='circle-bg' />
          <img src={testimonial1} alt='testimonial' />
          <p className='title'> Mr. Peeradech</p>
          <p className='title'> General Director of Padoongrich</p>
          <p style={{ textIndent: 32 }}>“We used to have fixed system proposals from the other companies, but the problem is the mismatch between modules. For example, we want to have the purchasing and repairing function in the same system as shipment operations.”</p>
          <p style={{ textIndent: 32 }}>“Our projects usually take an average of 3 months, which is not a long time considering they are fully customized to our needs. The cloud server cost is moderate, but the value we get is beyond compensated. We ensure safety and security of data because it’s in the hands of storage professionals. And Konsys also provides professional consulting to us.”</p>
        </TestimonialCard>
        <TestimonialCard>
          <img src={circle} alt='testimonial' className='circle-bg' />
          <img src={testimonial2} alt='testimonial' />
          <p className='title'>Ms. Kunnipa</p>
          <p className='title'>Management Director of Padoongrich</p>
          <p style={{ textIndent: 32 }}>“Since we are already in the B2B business, we see potential in leading the logistics industry in Thailand with our technology. We aim to reduce the number of trucks but grow more revenue in the coming years. With our expertise in the logistics industry, and technology, we can shift our business model to be a logistics platform provider.”</p>
        </TestimonialCard>
      </TestimonialWrapper>
      <StartButton style={{ width: 'fit-content', margin: 'auto', padding: '0 40px' }} onClick={() => setVisible(true)}>
        <p>Request Demo</p>
      </StartButton>
    </Section>
  </StacksSection>
)

export default Stacks
