import React from 'react'
import Pin from '../assets/address.svg'
import Email from '../assets/email.svg'
import Map from '../assets/pin.png'
import Phone from '../assets/phone.svg'
import {
  AddressContainer, AddressHeader, DetailWithIconWrapper, StartSectionAddress,
} from '../components/styled'

const DetailWithIcon = props => (
  <DetailWithIconWrapper {...props}>
    <div className="title">
      <img src={props.icon} alt='icon' />
      <p className='title'>{props.title}</p>
    </div>
    {
      props.children
    }
  </DetailWithIconWrapper>
)

export const Address = () => (
  <AddressContainer>
    <StartSectionAddress />
    <AddressHeader>CONTACT</AddressHeader>
    <a href="https://www.google.com/maps/place/Konsys/@13.7873356,100.5374833,15z/data=!4m5!3m4!1s0x0:0xcbec3485912a8689!8m2!3d13.7873356!4d100.5374833" target="_blank" rel="noopener noreferrer">
      <img src={Map} alt="Map" />
    </a>
    <div className='address'>
      <DetailWithIcon pin title='Address' icon={Pin}>
        <p>24 Chamnan Akson Alley, Khwaeng<br className='none-mobile'/>Phyathai, Khet Phyathai, Bangkok 10400</p>
        <p className='section-2'>Mon to Fri 10am - 8pm</p>
      </DetailWithIcon>
      <DetailWithIcon phone title='Phone Number' icon={Phone}>
        <p>Koh: +668-5427-7970</p>
        <p>Tee: +669-2444-5494</p>
      </DetailWithIcon>
      <DetailWithIcon email title='Email Address' icon={Email}>
        <p>nattawut@konsys.co</p>
        <p>teerapat@konsys.co</p>
      </DetailWithIcon>
    </div>
  </AddressContainer>
)

export default Address
