import React from 'react'
import checkCircle from '../../assets/padoongrich/check-circle.png'
import precisePlanning from '../../assets/padoongrich/Img-04.png'
import { Section, SectionBlock, StartButton } from './styled'

export const PrecisePlanning = ({ setVisible }) => (
  <div style={{ background: 'RGB(2, 18, 69)' }}>
    <Section style={{ marginTop: '-80px', paddingBottom: 40 }}>
      <img className='precise-image' src={precisePlanning} alt='precisePlanning' style={{ width: '100%', marginTop: '-48px', maxWidth: 1800 }}/>
      <SectionBlock>
        <div className='block'>
          <p className='header'>Precise Planning</p>
          <p className='header'>with <span style={{ color: '#3CD2AF' }}>Power BI</span></p>
          <StartButton style={{ width: 'fit-content', padding: '0 40px' }} onClick={() => setVisible(true)}>
            <p>Request Demo</p>
          </StartButton>
        </div>
        <div className='block'>
          <p className='detail'>“Power BI help managers to forecast more accurately. With cash flow visualization, we can manage our cash much more precisely and it just makes our life easier. We also see analysis of expenses, and identify which trucks spent too much fuel or cost a lot in repair.”</p>
          <p className='detail bullet'><img src={checkCircle} alt='checkCircle' style={{ width: 20, marginRight: 8 }}/>Data-driven decision making</p>
          <p className='detail bullet'><img src={checkCircle} alt='checkCircle' style={{ width: 20, marginRight: 8 }}/>Real-time update on job performance</p>
          <p className='detail bullet'><img src={checkCircle} alt='checkCircle' style={{ width: 20, marginRight: 8 }}/>Detect problem early on</p>
        </div>
      </SectionBlock>
    </Section>
  </div>
)

export default PrecisePlanning
