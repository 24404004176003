import React from 'react'
import designIcon from '../assets/design.svg'
import mobileIcon from '../assets/mobiledev.svg'
import mvpIcon from '../assets/mvp.svg'
import webIcon from '../assets/webdev.svg'
import {
  OurServiceBG, OurServiceContent, OurServiceDetail, OurServiceDetailRowRender, OurServicePicture, Stack, StackShadow, StartSectionOurService,
} from '../components/styled'

export const OurServices = () => (
  <div>
    <OurServiceBG>
      <OurServicePicture />
      <OurServiceContent>
        <StartSectionOurService />
        <p className='header'>OUR SERVICES</p>
        <p className='detail'>We make products that help your business looks even better.</p>
        <OurServiceDetailRowRender>
          <div>
            <OurServiceDetail design>
              <div className='title'>
                <img src={designIcon} alt='designIcon' />
                <p>Design</p>
              </div>
              <p>User Experience Design</p>
              <p>Visual & Graphic Design</p>
              <p>Product Design</p>
            </OurServiceDetail>
            <OurServiceDetail mvp>
              <div className='title'>
                <img src={mvpIcon} alt='mvpIcon' />
                <p>MVP Development</p>
              </div>
              <p>Quick Start</p>
              <p>Functional Prototype</p>
            </OurServiceDetail>
          </div>
          <div>
            <OurServiceDetail web>
              <div className='title'>
                <img src={webIcon} alt='webIcon' />
                <p>Web Development</p>
              </div>
              <p>Node.js</p>
              <p>Javascript (React)</p>
            </OurServiceDetail>
            <OurServiceDetail mobile>
              <div className='title'>
                <img src={mobileIcon} alt='mobileIcon' />
                <p>Mobile Development</p>
              </div>
              <p>iOS / Android</p>
              <p>React Native</p>
            </OurServiceDetail>
          </div>
        </OurServiceDetailRowRender>
      </OurServiceContent>
    </OurServiceBG>
    <div style={{
      display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%',
    }}>
      <Stack>
        <div className='docker' />
        <div className='graphql' />
        <div className='nodejs' />
        <div className='react' />
        <div className='mongodb' />
        <div className='google-cloud' />
        <div className='kubernetes' />
      </Stack>
      <StackShadow />
    </div>
  </div>
)

export default OurServices
