import React, { useRef, useEffect } from 'react'
import Slider from 'react-slick'
import {
  StartSectionHowWeWork, HowWeWorkContainer, DetailBoxContainer, HowWeWorkWhiteBox,
} from '../components/styled'
import theTeam from '../assets/theteam.svg'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../components/how-we-work.css'

export const HowWeWork = () => {
  let firstClientX
  let clientX

  const settings = {
    dots: false,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 1,
    touchThreshold: 10,
    infinite: false,
    centerMode: true,
  }

  const preventTouch = (e) => {
    const minValue = 5 // threshold

    clientX = e.touches[0].clientX - firstClientX

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault()
      e.returnValue = false

      return false
    }
    return false
  }
  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX
  }
  const containerRef = useRef()

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('touchstart', touchStart)
      containerRef.current.addEventListener('touchmove', preventTouch, {
        passive: false,
      })
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('touchstart', touchStart)
        containerRef.current.removeEventListener('touchmove', preventTouch, {
          passive: false,
        })
      }
    }
  })
  return (
    <HowWeWorkContainer>
      <StartSectionHowWeWork />
      <p>HOW WE WORK</p>
      <p className='sub-header'>Our end-to-end solutions mean that we can control the quality of product, <br />from the brainstorming phase to after our product launch.</p>
      <div className='none-mobile'>
        <DetailBoxContainer>
          <div className='how-we-work'>
            <div className='analytic' />
            <p className='main' >Business Analysis</p>
            <p className='detail' style={{
              marginTop: '16px',
            }}>We analyze your business ideas explicitly and help you visualize the end result as a software. In this way, everyone can definitely notice the goal we want to achieve.</p>
          </div>
          <div className='how-we-work'>
            <div className='design' />
            <p className='main' >Graphic Design</p>
            <p className='detail' style={{
              marginTop: '16px',
            }}>We create User Interface (UI) prototypes with a slick and eye-pleasing designs, because a user-friendly interface is the key to product success. A product is only useful if people want to use it!</p>
          </div>
          <div className='how-we-work'>
            <div className='coding' />
            <p className='main' >Software Development</p>
            <p className='detail' style={{
              marginTop: '16px',
            }}>We know how to develop web and mobile apps, as we have done quite a few. But, we also care to keep in constant touch with you to make sure that every team effort results in business value.</p>
          </div>
          <div className='how-we-work'>
            <div className='agile' />
            <p className='main' >Agile Approach</p>
            <p className='detail' style={{
              marginTop: '16px',
            }}>We mainly focus on agile software development (Scrum). Our team is self-organising which makes people more devoted to the project. The process is divided into short sprints, so we can deliver an update every two or four weeks.</p>
          </div>
          <div className='how-we-work'>
            <div className='growth' />
            <p className='main' >Launch & Maintenance</p>
            <p className='detail' style={{
              marginTop: '16px',
            }}>We always follow up during the launch of your project. In particular, we consider this software as our own and we want everything to run smoothly and flawless.</p>
          </div>
          <div className='how-we-work'>
            <div className='performance' />
            <p className='main' >Further Development</p>
            <p className='detail' style={{
              marginTop: '16px',
            }}>Every app needs improvements and new features – especially the successful ones! We help you gather feedback from your users and turn new ideas into reality, so you can take your business further down the road.</p>
          </div>
        </DetailBoxContainer>
      </div>
      <div className='mobile-only' style={{ marginTop: '32px', width: '100%' }} ref={containerRef}>
        <Slider {...settings}>
          <div>
            <HowWeWorkWhiteBox>
              <div className='analytic' />
              <p className='main' >Business Analysis</p>
              <p className='detail'>We analyze your business ideas explicitly and help you visualize the end result as a software. In this way, everyone can definitely notice the goal we want to achieve.</p>
            </HowWeWorkWhiteBox>
          </div>
          <div>
            <HowWeWorkWhiteBox>
              <div className='design' />
              <p className='main' >Graphic Design</p>
              <p className='detail'>We create User Interface (UI) prototypes with a slick and eye-pleasing designs, because a user-friendly interface is the key to product success. A product is only useful if people want to use it!</p>
            </HowWeWorkWhiteBox>
          </div>
          <div>
            <HowWeWorkWhiteBox>
              <div className='coding' />
              <p className='main' >Software Development</p>
              <p className='detail'>We know how to develop web and mobile apps, as we have done quite a few. But, we also care to keep in constant touch with you to make sure that every team effort results in business value.</p>
            </HowWeWorkWhiteBox>
          </div>
          <div>
            <HowWeWorkWhiteBox>
              <div className='agile' />
              <p className='main' >Agile Approach</p>
              <p className='detail'>We mainly focus on agile software development (Scrum). Our team is self-organising which makes people more devoted to the project. The process is divided into short sprints, so we can deliver an update every two or four weeks.</p>
            </HowWeWorkWhiteBox>
          </div>
          <div>
            <HowWeWorkWhiteBox>
              <div className='growth' />
              <p className='main' >Launch & Maintenance</p>
              <p className='detail'>We always follow up during the launch of your project. In particular, we consider this software as our own and we want everything to run smoothly and flawless.</p>
            </HowWeWorkWhiteBox>
          </div>
          <div>
            <HowWeWorkWhiteBox>
              <div className='performance' />
              <p className='main' >Further Development</p>
              <p className='detail'>Every app needs improvements and new features – especially the successful ones! We help you gather feedback from your users and turn new ideas into reality, so you can take your business further down the road.</p>
            </HowWeWorkWhiteBox>
          </div>
        </Slider>
      </div>
      <div className='mobile-only'>
        <img src={theTeam} alt='theTeam' style={{ width: '120%', marginLeft: '-13.5%', marginTop: '58px' }} />
        {/* <img src={theTeam} style={{ width: '140%', marginLeft: '-31.5%', marginTop: '38px' }} /> */}
      </div>
    </HowWeWorkContainer>
  )
}
export default HowWeWork
