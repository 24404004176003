import React from 'react'
import {
  StartSection, TheTeamBGContainer, TheTeamBoxContainer, TheTeamContainer, TheTeamContentContainer,
} from '../components/styled'

export const TheTeam = () => (
  <TheTeamContainer>
    <TheTeamBGContainer>
      <div className='the-team-bg' />
    </TheTeamBGContainer>
    <TheTeamContentContainer>
      <StartSection style={{ margin: '0 0 0 0' }} />
      <p>THE TEAM</p>
      <p className='sub-header'>We have a full-stack squad with designers and testers on board and we always ensure that the team is</p>
      <TheTeamBoxContainer>
        <div className='the-team'>
          <p className='main'>Productive</p>
          <p className='detail'>
            Our team has been working together for a while. They enjoy working as a team and have an open communication.</p>
        </div>
        <div className='the-team'>
          <p className='main'>Easy to Manage</p>
          <p className='detail'>
            We follow the principles of agile software development, which is short, circular and iterative. Development cycles are built upon nested feedback loops.</p>
        </div>
        <div className='the-team'>
          <p className='main'>Skilled & Experienced</p>
          <p className='detail'>
            Our developers have years of experience under their belt. They can make use of the latest tech tools but also capable of handling conventional tools.</p>
        </div>
        <div className='the-team'>
          <p className='main'>Adaptable & Agile</p>
          <p className='detail'>
            We love new challenges and we can get our heads around your project quickly, adapt to your practices and follow your guidelines.</p>
        </div>
      </TheTeamBoxContainer>
    </TheTeamContentContainer>
  </TheTeamContainer>
)

export default TheTeam
