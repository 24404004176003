import React, { useRef } from 'react'
import {
  Container, MobileBG, TopBG, TopLight,
} from '../components/styled'
import { Address } from './address'
import { Footer } from './footer'
import { Home } from './home'
import { HowWeWork } from './how-we-work'
import { Menu } from './menu'
import { OurProjects } from './our-projects'
import { OurServices } from './our-services'
import { TheTeam } from './the-team'
import { WhoWeAre } from './who-we-are'

export default () => {
  const whoAreWeRef = useRef(null)
  const ourServicesRef = useRef(null)
  const ourProjectsRef = useRef(null)
  const howWeWorkRef = useRef(null)
  const theTeamRef = useRef(null)
  const addressRef = useRef(null)
  return (
    <div>
      <Container>
        {/* <BG /> */}
        <Menu myRef={{
          whoAreWeRef,
          ourServicesRef,
          ourProjectsRef,
          howWeWorkRef,
          theTeamRef,
          addressRef,
        }} />
        <MobileBG>
          <TopBG>
            {/* <Header> */}
            <TopLight />
            <Home myRef={{
              whoAreWeRef,
              ourServicesRef,
              ourProjectsRef,
              howWeWorkRef,
              theTeamRef,
              addressRef,
            }} />
            {/* </Header> */}
          </TopBG>
          <div ref={whoAreWeRef} />
          <WhoWeAre />
        </MobileBG>
        <div ref={ourServicesRef} />
        <OurServices />
        <div ref={ourProjectsRef} />
        <div className='bg-parent'>
          <div className='hidden'>
            <div className='bg'>
              <OurProjects myRef={{
                ourProjectsRef,
              }} />
              <div ref={howWeWorkRef} />
              <HowWeWork />
              <div ref={theTeamRef} />
              <div className='bg-extra-for-mobile'>
                <TheTeam />
              </div>
              <div ref={addressRef} />
              <Address />
            </div>
          </div>
        </div>
        <Footer />
      </Container>
    </div>
  )
}
