import React, { useRef, useState } from 'react'
import { Divider, Modal } from 'antd'
import GoogleMapReact from 'google-map-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Link } from 'react-router-dom'
import {
  Container, MapContainer, MobileBG, TopBG, TopLight,
} from '../../components/styled'
import { DetailWithIconWrapper, AddressContainer, StartSectionAddress } from './styled'
import { Home } from './home'
import { Menu } from '../menu'
import { Benefits } from './benefits'
import { ChallengeResult } from './challenge-result'
import { PrecisePlanning } from './precise-planning'
import { DataAnalysis } from './data-analysis'
import { Stacks } from './stacks'
import Pin from '../../assets/address.svg'
import Email from '../../assets/email.svg'
import Map from '../../assets/padoongrich/google_maps_logo.png'
import Phone from '../../assets/phone.svg'
import PhoneCall from '../../assets/padoongrich/phone-call.png'
import Gmail from '../../assets/padoongrich/gmail.png'

const mailTemplate = `subject=[Request Demo] A customer has requested a Konsys demo!&body=Dear Konsys Team,%0d%0a%0d%0a
We have a new customer%0d%0a
  Name: %0d%0a
  Email: %0d%0a
  Message: %0d%0a%0d%0a
Please reach out to them ASAP!%0d%0a
Thanks!`

const Marker = () => (
    <div style={{
      display: 'inline-flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      transform: 'translate(-50%, -50%)',
    }}>
      <FontAwesomeIcon icon={solid('map-marker-alt')} style={{ color: 'red', fontSize: '20px' }} />
    </div>
)
const SimpleMap = () => {
  const defaultProps = {
    center: {
      lat: 13.785512591003789,
      lng: 100.5428877943114,
    },
    zoom: 19,
  }

  return (
    <div style={{
      height: '100%', width: '80%',
    }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_KEY_API_GOOGLE_MAPS }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
          <Marker
          lat={defaultProps.center.lat}
          lng={defaultProps.center.lng}
        />
      </GoogleMapReact>
      <div style={{
        display: 'flex', justifyContent: 'center', marginTop: '24px',
      }}>
        <button style={{
          backgroundColor: '#021345', borderRadius: '20px', border: '1px solid white', padding: '4px 24px',
        }}>
          <a href='https://goo.gl/maps/iYayhNBHd7vRWM3U8'>
          <p
              style={{
                fontWeight: 600, fontSize: 20, display: 'flex', alignItems: 'center', margin: '4px',
              }}
            >
              <img src={Map} style={{ marginRight: 8, width: 15 }} alt='maps' />
              Go to map
            </p>
            </a>
        </button>
      </div>
    </div>
  )
}

export default () => {
  const challengeResult = useRef(null)
  const benefitsRef = useRef(null)
  const [visible, setVisible] = useState(false)

  return (
    <div>
      <Modal
        open={visible}
        width={1000}
        onCancel={() => setVisible(false)}
        footer={[]}
        style={{ border: '1px solid #3982fa', borderRadius: '8px' }}
      >
        <p style={{
          color: 'white', fontSize: '40px', margin: '0 0 0 16px', fontWeight: 700,
        }}>Contact</p>
        <MapContainer>
          <div className='map'>
            <div style={{
              width: '100%', height: '70%', display: 'flex', justifyContent: 'center',
            }}>
              <SimpleMap />
              </div>
          </div>
          <div>
            <AddressContainer>
              <div className='address'>
                <div style={{ color: 'white', fontSize: '16px' }}>
                  <div style={{ display: 'flex' }}>
                    <i className="fal fa-map-marker-alt" style={{ color: '#37DED2', fontSize: '16px', margin: '2px 8px 0 0' }}/>
                    <p style={{ margin: '0px' }}>24 Chamnan Akson Alley, Khwaeng<br className='none-mobile'/>Phyathai, Khet Phyathai, Bangkok 10400</p>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <i className="fal fa-clock" style={{ color: '#37DED2', fontSize: '16px', margin: '0 8px 0 0' }}/>
                  <p style={{ color: 'white', margin: '0px' }}>Mon to Fri 10am - 8pm</p>
                </div>
                <Divider style={{ backgroundColor: '#175e74', height: '2px' }}/>
                <a href='tel:+66854277970' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex' }}>
                  <i className="fal fa-phone-alt" style={{ color: '#37DED2', fontSize: '16px', margin: '0 8px 0 0' }} />
                  <p style={{ color: 'white', margin: '0px' }}>Koh: +668-5427-7970</p>
                  </div>
                  <p style={{
                    fontWeight: 600, fontSize: 16, display: 'flex', alignItems: 'center', color: '#37DED2', margin: '0px',
                  }}>Call</p>
                </a>
                <a href='tel:+66854277970' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex' }}>
                  <i className="fal fa-phone-alt" style={{ color: '#37DED2', fontSize: '16px', margin: '0 8px 0 0' }} />
                    <p style={{ color: 'white', margin: '0px' }}>Tee: +669-2444-5494</p>
                  </div>
                  <p style={{
                    fontWeight: 600, fontSize: 16, display: 'flex', alignItems: 'center', color: '#37DED2', margin: '0px',
                  }}>Call</p>
                </a>
                <Divider style={{ backgroundColor: '#175e74', height: '2px' }}/>
                <a href={`mailto:nattawut@konsys.co?${mailTemplate}`} style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <i className="fal fa-envelope" style={{ color: '#37DED2', fontSize: '16px', margin: '0 8px 0 0' }}/>
                  <p style={{ color: 'white', margin: '0px' }}>nattawut@konsys.co</p>
                  </div>
                  <p style={{
                    fontWeight: 600, fontSize: 16, display: 'flex', alignItems: 'center', color: '#37DED2', margin: '0px',
                  }}>Call</p>
                </a>
                <a href={`mailto:teerapat@konsys.co?${mailTemplate}`} style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <i className="fal fa-envelope" style={{ color: '#37DED2', fontSize: '16px', margin: '0 8px 0 0' }}/>
                  <p style={{ color: 'white', margin: '0px' }}>teerapat@konsys.co</p>
                  </div>
                  <p style={{
                    fontWeight: 600, fontSize: 16, display: 'flex', alignItems: 'center', color: '#37DED2', margin: '0px',
                  }}>Call</p>
                </a>
              </div>
            </AddressContainer>
          </div>
        </MapContainer>
      </Modal>
      <Container>
        <Menu
        disableContact
        myRef={{
          challengeResult,
          benefitsRef,
        }} />
        <MobileBG>
          <TopBG>
            <TopLight />
            <Home myRef={{
              challengeResult,
              benefitsRef,
            }} />
          </TopBG>
          <div ref={challengeResult} />
          <ChallengeResult />
        </MobileBG>
        <Benefits setVisible={setVisible} />
        <PrecisePlanning setVisible={setVisible} />
        <DataAnalysis setVisible={setVisible} />
        <Stacks setVisible={setVisible} />
      </Container>
    </div>
  )
}
