import styled, { css } from 'styled-components'

export const Header = styled.div`
  text-align: center;
  > h1 {
    font-size: 60px;
  @media only screen and (max-width: 1440px) {
    font-size: 60px;
    }
  @media only screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 425px) {
    font-size: 30px;
  }
}
`
export const Selector = styled.div`
  display: flex;
  justify-content: center;
  width: 70%;
  flex-wrap: wrap;
  > p {
    font-size: 18px;
    margin: 0px 8px;
    font-weight: 700;
    cursor: pointer;
    @media only screen and (max-width: 1440px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    @media only screen and (max-width: 425px) {
      font-size: 12px;
    }
  }
  >p.about-us-selector {
    text-decoration: underline;
    color: #37DED2;
    font-size: 18px;
    font-weight: 700,
    @media only screen and (max-width: 1440px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    @media only screen and (max-width: 425px) {
      font-size: 12px;
    }
  }
`

export const Content = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: center;
  > div {
    width: 22%;
    @media only screen and (max-width: 1440px) {
      width: 22%
    }
    @media only screen and (max-width: 768px) {
      width: 100%
      max-width: 320px
    }
    @media only screen and (max-width: 425px) {
      width: 60%
    }
  }
`

export const Label = styled.div`
  margin: 0 0 8px 12px
  > p.name {
    margin: 0
    font-size: 18px
    font-weight: 700
    color: white
    @media only screen and (max-width: 1440px) {
      width: 50%
    }
    @media only screen and (max-width: 768px) {
      width: 70%
    }
    @media only screen and (max-width: 425px) {
      font-size: 12px
    }
  }
  > p.role {
    margin: 0
    font-size: 14px
    font-weight: 700
    color: #37DED2
    @media only screen and (max-width: 1440px) {
      // width: 50%
    }
    @media only screen and (max-width: 768px) {
      // width: 70%
    }
    @media only screen and (max-width: 425px) {
      font-size: 10px
    }
  }
`
