import React from 'react'
import icon01 from '../../assets/padoongrich/Icon-01.png'
import icon02 from '../../assets/padoongrich/Icon-02.png'
import icon03 from '../../assets/padoongrich/Icon-03.png'
import icon04 from '../../assets/padoongrich/Icon-04.png'
import icon05 from '../../assets/padoongrich/Icon-05.png'
import {
  Artwork, BlurBlock, FiveBox, HomeContainer, Section, StartButton,
} from './styled'

export const Home = ({ myRef }) => (
  <HomeContainer>
    <h1 style={{ fontFamily: 'Raleway', fontWeight: 700 }}>Padoongrich</h1>
    <h1 style={{ fontFamily: 'Raleway', fontWeight: 200, margin: 0 }}>Transformation Journey</h1>
    <p>A B2B Transportation Platform</p>
    <StartButton onClick={ () => window.scrollTo(0, myRef.challengeResult.current.offsetTop - 77)}>
      <p>Continue Reading</p>
    </StartButton>
    <Artwork />
    <BlurBlock>
      <Section style={{ display: 'flex' }}>
        <FiveBox>
          <img src={icon01} alt='icon01' />
          <p>#1 in B2B Logistics</p>
        </FiveBox>
        <FiveBox>
          <img src={icon02} alt='icon02' />
          <p>50+ Years Operations</p>
        </FiveBox>
        <FiveBox>
          <img src={icon03} alt='icon03' />
          <p>New Leadership</p>
        </FiveBox>
        <FiveBox>
          <img src={icon04} alt='icon04' />
          <p>Innovation Driven</p>
        </FiveBox>
        <FiveBox style={{ borderRight: 'none' }}>
          <img src={icon05} alt='icon05' />
          <p>from Zero to Hero</p>
        </FiveBox>
      </Section>
    </BlurBlock>
  </HomeContainer >
)

export default Home
