import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import dataVisualize from '../../assets/padoongrich/Img-05.png'
import mockPc from '../../assets/padoongrich/Img-06.png'
import {
  BenefitBG, BenefitContent, BenefitDetailRowRender, Section, SideImage, StartButton, StartSectionBenefit, TransparentButton,
} from './styled'

export const DataAnalysis = ({ setVisible }) => (
  <div style={{ background: 'linear-gradient(180deg, #021245, #010934)' }}>
    <Section style={{ padding: '40px 0 80px 0' }}>
      <BenefitBG>
        <SideImage src={dataVisualize} alt='moveCloud' align='left' />
        <BenefitContent>
          <StartSectionBenefit />
          <p className='header'>Data Visualization Can</p>
          <p className='header'>Reveal Valuable Insights.</p>
          <BenefitDetailRowRender>
            <p>1.Power BI has helped the management team to forecast more accurately. With the cash flow visualization, we can manage our cash much more precisely and it just makes our life easier. We also have a summary of expenses, to see which trucks spent fuel inefficiently or cost a lot to repair.</p>
            <p>2.We used to gather data from our finance department and it takes a lot of time and effort to compile. There’s a lot of numbers! Power BI integrates with our ERP and PEAK so we don't have to process anything. The data just shows up, ready for analysing.</p>
            <p>3.We provided data access for each department. So, they can back up their actions with data and see their work results. For example, in procurement, they can see how much they spent on spare parts and tires. Then, trace back how their cost reduction strategy has worked.</p>
            <TransparentButton onClick={() => setVisible(true)}>
              Read more
              <FontAwesomeIcon icon={solid('arrow-right')} style={{ marginLeft: 8 }}/>
            </TransparentButton>
          </BenefitDetailRowRender>
        </BenefitContent>
      </BenefitBG>
      <BenefitBG>
        <BenefitContent>
          <StartSectionBenefit />
          <p className='header'>Data-driven job assignment</p>
          <p className='header'>and all-in-one shipment</p>
          <p className='header'>tracking system.</p>
          <BenefitDetailRowRender>
            <p>The goal is that assigning jobs should be easier and more straightforward, so</p>
            <ul>
              <li><p>we make the decision more data-driven and eliminate bias</p></li>
              <li><p>Employees can assign bulk tickets to drivers and see drivers status in real-time.</p></li>
            </ul>
            <StartButton style={{ width: 'fit-content', margin: '40px 0', padding: '0 40px' }} onClick={() => setVisible(true)}>
              <p>Request Demo</p>
            </StartButton>
      </BenefitDetailRowRender>
        </BenefitContent>
        <SideImage src={mockPc} alt='saveCost' align='right' />
      </BenefitBG>
    </Section>
  </div>
)

export default DataAnalysis
