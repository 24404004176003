import React from 'react'
import {
  unstable_HistoryRouter as HistoryRouter, Routes, Route,
} from 'react-router-dom'
import { createBrowserHistory } from 'history'
import App from './pages'
import Padoongrich from './pages/padoongrich'
import './App.css'
import AboutUs from './pages/about-us'

const history = createBrowserHistory({ window })

const Index = () => {
  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path='/' element={<App />} />
        <Route path='/about-us' element={<AboutUs/>}/>
        <Route path='/case-studies/padoongrich' element={<Padoongrich />} />
        {/* <Route path='*' element={<NotFoundPage />} /> */}
      </Routes>
    </HistoryRouter>
  )
}

export default Index
