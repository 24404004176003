import React, { useEffect, useRef, useState } from 'react'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import {
  Container, MobileBG, TopBG, TopLight,
} from '../../components/styled'

import {
  Menu,
} from '../menu'

import {
  Footer,
} from '../footer'

// import test2 from '../../assets/profile_images/test.jpg'
import alice from '../../assets/profile_images/alice.jpg'
import boss from '../../assets/profile_images/boss.jpg'
import fah from '../../assets/profile_images/fah.jpg'
import fluke from '../../assets/profile_images/fluke.jpg'
import gade from '../../assets/profile_images/gade.jpg'
import irene from '../../assets/profile_images/irene.jpg'
import koh from '../../assets/profile_images/koh.jpg'
import napat from '../../assets/profile_images/napat.jpg'
import NUT from '../../assets/profile_images/NUT.jpg'
import pang from '../../assets/profile_images/pang.jpg'
import ploy from '../../assets/profile_images/ploy.jpg'
import potae from '../../assets/profile_images/potae.jpg'
import pun from '../../assets/profile_images/pun.jpg'
import tee from '../../assets/profile_images/tee.jpg'
import ter from '../../assets/profile_images/ter.jpg'
import {
  Content, Header, Label, Selector,
} from './styled'

const ROLE = {
  ALL: {
    key: 'ALL',
    value: 'All',
  },
  DEVELOPER: {
    key: 'DEVELOPER',
    value: 'Engineer',
  },
  PROJECT_OWNER: {
    key: 'PROJECT_OWNER',
    value: 'Co-founders',
  },
  MARKETING: {
    key: 'MARKETING',
    value: 'Business',
  },
}

const alldata = [
  {
    img: koh,
    title: ['Chief Executive Officer'],
    role: ROLE.PROJECT_OWNER.key,
    name: 'Nattawut (Koh)',
    description: ['Let us introduce you to Koh, our charismatic CEO and the visionary force behind Konsys. Koh guides our company to success and builds strong partnerships with a focus on one important principle: "people are the most crucial aspect of business and management." Thanks to his leadership, Konsys has achieved great things with a fantastic team.', 'Koh\'s mission for Konsys is to help enterprises harness technology for better efficiency and reliability. By working closely with our clients, he ensures they stay ahead of the game with the latest innovations. We\'re truly grateful to have such a dedicated and inspiring leader steering the Konsys ship!'],
  },
  {
    img: NUT,
    title: ['Chief Information Officer'],
    role: ROLE.PROJECT_OWNER.key,
    name: 'Nuttakit (Nut)',
    description: ['We\'d like you to meet Nut, our friendly and resourceful CIO at Konsys. Nut is all about combining curiosity and technical expertise to make sure everyone has a great work-life balance. And guess what? Nut is also the proud parent of our two adorable company mascots, Irene and Alice the Beagles. They fill the office with fun and laughter through their playful antics.', 'Oh, and did we mention Nut is super strong? Seriously, you might want to think twice before challenging him in sports! We\'re so lucky to have a CIO like Nut on board. His humor, curiosity, and passion for technology really make our working environment vibrant and enjoyable for everyone.'],
  },
  {
    img: potae,
    title: ['Chief Technology Officer'],
    role: ROLE.PROJECT_OWNER.key,
    name: 'Supasit (Potae)',
    description: ['Get ready to meet Potae, our remarkable CTO who\'s known for his eagle eye for detail! He skillfully juggles our technical operations and ensures everyone on the team feels valued and cared for.', 'Wearing the hat of Scrum Master at Konsys, Potae works his magic with agile methods and ceremonies. He\'s the maestro of sprint retrospectives, constantly pushing to enhance our company processes while showcasing his expertise in time management and prioritization.', 'His unique mix of technical prowess and genuine empathy makes him a standout and much-loved member of the Konsys family.'],
  },
  {
    img: ploy,
    title: ['Senior Front-end Engineer', 'Project Manager'],
    role: ROLE.DEVELOPER.key,
    name: 'Lalida (Ploy)',
    description: ['As we welcome P\'Ploy to our Konsys team, we are graced with not only her vast expertise and unshakable work ethic, but also her endearing warmth and charm.', 'When P\'Ploy is not hard at work, she immerses herself in the captivating world of Japanese language and culture, her mind lost in thoughts of the next adventure that awaits her in the Land of the Rising Sun.', 'But there is more to her than her love for all things Japan. One cannot help but be moved by her compassion and kindness towards the furry creatures of our world. Once, she took in a stray cat in dire need of help, and with her nurturing touch, brought it back to health. Her love for cats is simply boundless!', 'P\'Ploy\'s story is one that leaves us yearning to know more about this amazing engineer with a heart of gold.'],
  },
  {
    img: fluke,
    title: ['Software Engineer'],
    role: ROLE.DEVELOPER.key,
    name: 'Suppavich (Fluke)',
    description: ['Get ready for Fluke, our energetic Software Engineer at Konsys, who lights up the room and brightens our days! He\'s got this unwavering drive and a knack for adapting, which makes our projects soar, while his fun-loving vibe keeps everyone pumped.', 'Count on Fluke to liven up the scene with his contagious excitement for socializing - he\'s our go-to party booster! A badminton aficionado, he stays nimble and promotes unity among his colleagues.', 'With Fluke\'s enthusiasm for life, he infuses our team with endless energy and cheer. We\'re over the moon to have such a lovable, lively, and reliable team member like Fluke by our side!'],
  },
  {
    img: fah,
    title: ['Senior Back-end Engineer'],
    role: ROLE.DEVELOPER.key,
    name: 'Rujira (Fah)',
    description: ['Introducing Fah, our exceptional Senior Back-end Engineers at Konsys who lives by the motto: "You will always write better code than yesterday\'s you." And trust us, she truly does!.', 'When Fah isn\'t busy with code, she\'s often found whipping up delicious meals in the kitchen or engaging in deep conversations on a wide variety of topics. It\'s her relentless inquisitiveness and eagerness to acquire knowledge that sets her apart.', 'She is also a gentle and empathetic member of the team, who harbors a deep fondness for her colleagues. Her lively and spirited persona is what makes her an invaluable asset to the Konsys family.', 'If you should ever cross paths with Fah, do not hesitate to engage her in conversation. Who knows what fascinating insights she may impart upon you.'],
  },
  {
    img: gade,
    title: ['Admin & Business Development'],
    role: ROLE.MARKETING.key,
    name: 'Hatairat (Gade)',
    description: ['Hey there, let me introduce you to Gade, a truly caring and warm-hearted girl at Konsys.', 'You can always count on her because she\'s responsible and endlessly supportive, making her a total MVP for the team. Plus, she\'s a bunny mom to the cutest little fluffballs, Muffin and Croissant, Gade cherishes the tranquility they bring and enjoys being surrounded by peaceful nature.', 'When she\'s not busy being awesome at work, Gade loves to hit up stunning destinations and chow down on incredible food.', 'Gade\'s got this magnetic charm and rock-solid reliability that instantly wins people over. As Khun Koh\'s right-hand person, she gracefully handles everything thrown her way and always has her colleagues\' backs. Seriously, Gade\'s just the best!'],
  },
  {
    img: tee,
    title: ['Business & IT Consultant'],
    role: ROLE.MARKETING.key,
    name: 'Teerapat (Tee)',
    description: ['Hey, let me tell you about Tee, a kickass software engineer at Konsys who\'s always pushing the envelope with innovation and teamwork. He\'s got mad skills in consulting, and product management, and has been around the block, from crafting slick user-friendly products to building powerhouse enterprise systems.', 'Tee\'s a learning enthusiast, always hungry for knowledge and eager to share his wisdom as a content creator, stirring up a collaborative vibe within the team. He\'s got an eye on the future too, being super into artificial intelligence and how it\'s gonna shake up the industry.', 'Tee\'s a shining beacon of optimism and forward-thinking in the fast-paced digital world. He\'s got a knack for bringing people together and sparking collaboration, which shows off his diplomatic flair and leadership talents. Bottom line: Tee\'s an absolute gem for Konsys, and we\'re lucky to have him on board.'],
  },
  {
    img: pang,
    title: ['Quality Assurance'],
    role: ROLE.MARKETING.key,
    name: 'Prakaifa (Pang)',
    description: ['Meet Pang, a hardworking and adaptable QA tester at Konsys. She\'s known for her responsible and team-oriented approach to work. With her sharp analytical skills and intuition, Pang pays close attention to details and is fully committed to ensuring top quality.', 'When she\'s not working, Pang dreams of traveling the world to discover new experiences and adventures. A true animal lover, she enjoys spending her free time at cat and dog cafes, where she can hang out with her furry friends.', 'Pang is always looking to grow, both professionally and personally. She\'s currently learning new languages and even trying her hand at writing a book. Her enthusiasm for constant learning and self-improvement is truly inspiring to her teammates at Konsys.'],
  },
  {
    img: ter,
    title: ['Quality Control & Tester'],
    role: ROLE.MARKETING.key,
    name: 'Wasawat (Ter)',
    description: ['Let me tell you about Ter, our totally exceptional QA tester at Konsys. This guy\'s got curiosity and persistence nailed down, making sure every project is top-notch. He\'s all about collaboration and keeping an open mind while he works his magic.', 'Ter\'s got this infectious charisma that makes him a true asset on the team. He\'s got this offbeat sense of humor and a mad love for 90\'s tunes that just amps up the office vibes. His enthusiasm and positivity pump everyone up like nothing else.', 'And when it comes to parties? Ter\'s all in. He\'ll be the first one to raise a glass or jump into a gaming session. His upbeat attitude and unique humor make Ter a truly remarkable teammate.'],
  },
  {
    img: boss,
    title: ['Software Engineer'],
    role: ROLE.DEVELOPER.key,
    name: 'Wichaphon (Boss)',
    description: ['Let me introduce you to Boss, our awesome junior front-end developer intern at Konsys. He\'s a quick learner and a dedicated team player, always ready to roll up his sleeves and dive into projects with a can-do attitude.', 'Boss\'s vibrant personality and humor light up the office, and his optimism is downright contagious. You\'ll find him chatting about his favorite animes or getting lost in immersive games, bringing a creative and adventurous spark to Konsys. Trust me, this guy\'s a keeper.'],
  },
  {
    img: napat,
    title: ['Software Engineer'],
    role: ROLE.DEVELOPER.key,
    name: 'Napat (Nut)',
    description: ['Let me introduce you to Nut, our kickass junior back-end engineer intern at Konsys. He\'s a sharp and hardworking guy who brings a flexible and team-oriented mindset to the table, making sure our projects are on track for success.', 'Nut\'s got a soft spot for vintage tunes and can strum a mean guitar, adding a touch of artistry to the team. His sense of humor keeps the office lively, but don\'t be fooled - he\'s all about assertiveness and dedication when it comes to getting the job done. Nut\'s just the kind of teammate you want around.'],
  },
  {
    img: pun,
    title: ['Software Engineer'],
    role: ROLE.DEVELOPER.key,
    name: 'Saran (Pan)',
    description: ['So, we\'ve got this engineer, Pan, a junior back-end engineer at Konsys, and I\'ve gotta say, the guy\'s got some serious smarts. He\'s curious, ambitious, and intuitive with a knack for problem-solving. His confidence and teamwork skills make him an awesome addition to our crew.', 'Outside of work, Pan\'s a cool guy too. He loves cooking and gaming, which adds some extra fun to our team dynamic. Overall, Pan\'s a real catch and a cherished part of the Konsys family.'],
  },
  {
    img: irene,
    title: ['El Presidente'],
    role: '',
    name: 'Irene',
    description: ['Let me tell you about the real boss at Konsys - Irene, the Beagle. She\'s got the office wrapped around her paw, and it\'s no surprise why. She\'s curious, cute, and maybe a little naughty, but who can resist her charm? As the self-appointed El Presidente, Irene\'s all about enthusiasm, energy, and spreading joy.', 'But watch out! Irene\'s got a thing for unattended stuff and snacks, so if you\'re not careful, your belongings might become part of her treasure collection. Don\'t worry though, she\'ll return the favor by keeping your chair warm and helping you focus on work. She\'ll hit you with those irresistible puppy eyes, leaving you powerless against her undeniable cuteness.'],
  },
  {
    img: alice,
    title: ['No#1 Borderland'],
    role: '',
    name: 'Alice',
    description: ['Let me introduce you to Alice, the infamous Irene\'s partner in crime at Konsys. This little beagle is a bundle of energy and curiosity. She\'s known as the office\'s top troublemaker, but let\'s be real, she\'s just too cute to get mad at.', 'Alice is like Irene\'s wilder little sister, when she\'s not plotting her next prank or stealing slippers, you\'ll find her roping Irene into all sorts of playful mischief.', 'Together, Irene and Alice are a dynamic duo that brings a whole lot of joy, energy, and fun to the Konsys office. Thanks to these two, every day is a memorable adventure for everyone involved.'],
  },
]
const selectData = (role) => {
  const data = []
  alldata.forEach((element) => {
    if (element.role === role) {
      data.push(element)
    }
  })
  return data
}

const Box = ({
  imageSrc, description, name, role, title,
}) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <div className='' style={{ margin: '10px 10px 0 10px' }}>
      <div style={{ position: 'relative', borderRadius: expanded ? '0px' : '0px 0px 10px 10px' }}>
      <img src={imageSrc} alt={name} style={{
        width: '100%', height: 'auto', backgroundSize: 'cover', borderRadius: expanded ? '10px 10px 0px 0px' : '10px', display: 'block',
      }}/>
        <div
        className='effect-bottom-photo'
        style={{
          position: 'absolute', bottom: '0px', left: '0px', width: '100%',
        }}>
        </div>
        <span style={{
          position: 'absolute', bottom: '0px', left: '0px', zIndex: 5,
        }}>
            <Label style={{ margin: '16px' }}>
            {title?.map(item => (
              <p className='role' key={item} style={{ whiteSpace: 'pre-wrap' }}>
                {item}
                <br/>
              </p>
            ))}
              <p className='name' style={{ whiteSpace: 'pre', marginTop: '2px' }}>
                {name}
              </p>
            </Label>
          </span>
        {expanded
          ? <CaretUpOutlined style={{
            position: 'absolute', bottom: '16px', right: '16px', backgroundColor: '#FFFFFF', border: 'none', cursor: 'pointer', borderRadius: '20px', padding: '4px', color: 'rgba(30, 110, 235, 0.5)', zIndex: 10, fontSize: '12px',
          }} onClick={() => setExpanded(!expanded)}
        />
          : <CaretDownOutlined style={{
            position: 'absolute', bottom: '16px', right: '16px', backgroundColor: '#37DED2', border: 'none', cursor: 'pointer', borderRadius: '20px', padding: '4px', color: 'rgba(30, 110, 235, 0.5)', zIndex: 10, fontSize: '12px',
          }} onClick={() => setExpanded(!expanded)}
        />
      }
      </div>
      {expanded && (
        <div style={{ backgroundColor: 'white', borderRadius: '0px 0px 10px 10px', padding: '4px 16px' }}>
          {description?.map(item => (
          <p key={item} style={{ whiteSpace: 'pre-wrap', fontSize: '12px' }}>
            {item}
            <br/>
            </p>))}
        </div>
      )}
    </div>
  )
}

export const AboutUs = () => {
  const [selectedRole, setSelectedRole] = useState('ALL')
  const [data, setData] = useState(alldata)

  const whoAreWeRef = useRef(null)
  const ourServicesRef = useRef(null)
  const ourProjectsRef = useRef(null)
  const howWeWorkRef = useRef(null)
  const theTeamRef = useRef(null)
  const addressRef = useRef(null)

  useEffect(() => {
    if (selectedRole === ROLE.ALL.key) {
      setData(alldata)
    } else {
      setData(selectData(selectedRole))
    }
  }, [selectedRole])

  const creatArrayForLoop = () => {
    if (window.innerWidth >= 1440) {
      return [1, 1, 1, 1]
    }
    if (window.innerWidth < 1440 && window.innerWidth >= 768) {
      return [1, 1, 1]
    }
    return [1]
  }
  const arrayForLoop = creatArrayForLoop()

  return (
    <div>
      <Container>
        <Menu
        disableContact
        myRef={{
          whoAreWeRef,
          ourServicesRef,
          ourProjectsRef,
          howWeWorkRef,
          theTeamRef,
          addressRef,
        }}/>
        <MobileBG>
          <TopBG>
            <TopLight />
            {/* </Header> */}
          <div style={{ height: 'auto', padding: '20px 0px' }}>
            <div
            style={{
              display: 'flex', justifyContent: 'center', color: 'white', alignItems: 'center', flexDirection: 'column',
            }}>
              <Header>
                <h1 style={{ marginBottom: '-24px' }}>
                GET TO KNOW US
                </h1>
                <h1 style={{ color: '#37DED2' }}>OUR TEAM</h1>
                </Header>
            </div>
            <div
            style={{
              display: 'flex', justifyContent: 'center', color: 'white',
            }}
            >
              <Selector>
                <p className={selectedRole === ROLE.ALL.key && 'about-us-selector'} onClick={() => setSelectedRole(ROLE.ALL.key)}>{ROLE.ALL.value}</p>
                <p className={selectedRole === ROLE.DEVELOPER.key && 'about-us-selector'} onClick={() => setSelectedRole(ROLE.DEVELOPER.key)}>{ROLE.DEVELOPER.value}</p>
                <p className={selectedRole === ROLE.MARKETING.key && 'about-us-selector'} onClick={() => setSelectedRole(ROLE.MARKETING.key)}>{ROLE.MARKETING.value}</p>
                <p className={selectedRole === ROLE.PROJECT_OWNER.key && 'about-us-selector'} onClick={() => setSelectedRole(ROLE.PROJECT_OWNER.key)}>{ROLE.PROJECT_OWNER.value}</p>
                </Selector>
            </div>
            <Content>
              {
                arrayForLoop.map((_, i) => (
                  <div key={i}>
              {data.map((element, index) => {
                if (index % arrayForLoop.length === i) {
                  return (
                <Box
                key={element?.name}
                name={element?.name}
                role={ROLE?.[element.role]?.value}
                imageSrc={element?.img}
                description={element.description}
                title={element.title}
                />
                  )
                }
                return null
              })
              }
              </div>
                ))
              }
            </Content>
          </div>
          </TopBG>
        </MobileBG>
        <Footer />
      </Container>
    </div>
  )
}

export default AboutUs
