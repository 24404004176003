/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react'
import HamburgerMenu from 'react-hamburger-menu'
import { Link } from 'react-router-dom'
import {
  MenuBar, MenuContent, FixMenuBar, DefaultMenu, VerticalMenu,
} from '../components/styled'

import Logo from '../assets/Konsys on Black.png'

export const Menu = ({ myRef, disableContact }) => {
  const [scrolled, setScrolled] = useState(window.pageYOffset > 104)
  const [smooth, setSmooth] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 104) {
        setScrolled(true)
        if (window.pageYOffset < 200) setSmooth(window.pageYOffset - 200)
        else setSmooth(0)
      } else setScrolled(false)
    }
    window.addEventListener('scroll', handleScroll)
    return (() => {
      window.removeEventListener('scroll', handleScroll)
    })
  })
  const scroll = (ref) => {
    console.log(Date.now())
    window.scrollTo(0, ref)
    console.log(Date.now())
    setIsOpen(false)
  }
  return (
    !scrolled
      ? <MenuBar isOpen={isOpen}>
        <DefaultMenu>
          <Link to='/'>
            <img src={Logo} alt="" style={{ cursor: 'poiter', marginTop: '-3px' }} />
          </Link>
          <MenuContent className='menu-desktop-only'>
            <p onClick={() => window.location = '/about-us'}>Get to know us</p>
            <p onClick={() => window.location = '/case-studies/padoongrich'}>Case Study 1</p>
            {/* <p onClick={() => scroll(myRef.ourServicesRef.current.offsetTop - 70)} >OUR SERVICES</p>
            <p onClick={() => scroll(myRef.ourProjectsRef.current.offsetTop - 70)} >OUR PROJECTS</p>
            <p onClick={() => scroll(myRef.ourProjectsRef.current.offsetTop + myRef.howWeWorkRef.current.offsetTop - 0)} >HOW WE WORK</p>
            <p onClick={() => scroll(myRef.ourProjectsRef.current.offsetTop + myRef.theTeamRef.current.offsetTop - 0)} >THE TEAM</p> */}
            {
            !disableContact && <div onClick={() => scroll(myRef.ourProjectsRef.current.offsetTop + myRef.addressRef.current.offsetTop + 100)} >
              <p>CONTACT US</p>
            </div>
            }
          </MenuContent>
          <div className='menu-mobile-tablet' style={{ cursor: 'pointer' }}>
            <HamburgerMenu
              isOpen={isOpen}
              menuClicked={() => setIsOpen(!isOpen)}
              width={24}
              height={20}
              strokeWidth={2}
              rotate={0}
              color='white'
              borderRadius={0}
              animationDuration={0.5}
            />
          </div>
        </DefaultMenu>
        <div className='desktop-only'>
          <VerticalMenu isOpen={isOpen} header>
            <p onClick={() => window.location = '/about-us'}>Get to know us</p>
            <p onClick={() => window.location = '/case-studies/padoongrich'}>Case Study 1</p>
            {/* <p onClick={() => scroll(myRef.ourServicesRef.current.offsetTop - 70)} >OUR SERVICES</p>
            <p onClick={() => scroll(myRef.ourProjectsRef.current.offsetTop - 70)} >OUR PROJECTS</p>
            <p onClick={() => scroll(myRef.ourProjectsRef.current.offsetTop + myRef.howWeWorkRef.current.offsetTop - 50)} >HOW WE WORK</p>
            <p onClick={() => scroll(myRef.ourProjectsRef.current.offsetTop + myRef.theTeamRef.current.offsetTop - 50)} >THE TEAM</p> */}
            {!disableContact && <p onClick={() => scroll(myRef.ourProjectsRef.current.offsetTop + myRef.addressRef.current.offsetTop + 10)} >CONTACT</p>}
          </VerticalMenu>
        </div>
        <div className='menu-mobile-only'>
          <VerticalMenu isOpen={isOpen} header>
            <p onClick={() => window.location = '/about-us'}>Get to know us</p>
            <p onClick={() => window.location = '/case-studies/padoongrich'}>Case Study 1</p>
            {/* <p onClick={() => scroll(myRef.ourServicesRef.current.offsetTop - 20)} >OUR SERVICES</p>
            <p onClick={() => scroll(myRef.ourProjectsRef.current.offsetTop - 0)} >OUR PROJECTS</p>
            <p onClick={() => scroll(myRef.ourProjectsRef.current.offsetTop + myRef.howWeWorkRef.current.offsetTop - 30)} >HOW WE WORK</p>
            <p onClick={() => scroll(myRef.ourProjectsRef.current.offsetTop + myRef.theTeamRef.current.offsetTop - 80)} >THE TEAM</p> */}
            {!disableContact && <p onClick={() => scroll(myRef.ourProjectsRef.current.offsetTop + myRef.addressRef.current.offsetTop - 30)} >CONTACT</p>}
          </VerticalMenu>
        </div>
      </MenuBar>
      : <FixMenuBar style={{ marginTop: smooth }}>
        <DefaultMenu>
          <Link to='/'>
            <img src={Logo} alt="" style={{ cursor: 'poiter', marginTop: '-3px' }} />
          </Link>
          <MenuContent className='menu-desktop-only'>
            <p onClick={() => window.location = '/about-us'}>Get to know us</p>
            <p onClick={() => window.location = '/case-studies/padoongrich'}>Case Study 1</p>
            {/* <p onClick={() => scroll(myRef.ourServicesRef.current.offsetTop - 70)} >OUR SERVICES</p>
            <p onClick={() => scroll(myRef.ourProjectsRef.current.offsetTop - 70)} >OUR PROJECTS</p>
            <p onClick={() => scroll(myRef.ourProjectsRef.current.offsetTop + myRef.howWeWorkRef.current.offsetTop - 0)} >HOW WE WORK</p>
            <p onClick={() => scroll(myRef.ourProjectsRef.current.offsetTop + myRef.theTeamRef.current.offsetTop - 0)} >THE TEAM</p> */}
            {!disableContact && <div onClick={() => scroll(myRef.ourProjectsRef.current.offsetTop + myRef.addressRef.current.offsetTop + 100)} >
              <p>CONTACT</p>
            </div>}
          </MenuContent>
          <div className='menu-mobile-tablet' style={{ cursor: 'pointer' }}>
            <HamburgerMenu
              isOpen={isOpen}
              menuClicked={() => setIsOpen(!isOpen)}
              width={24}
              height={20}
              strokeWidth={2}
              rotate={0}
              color='white'
              borderRadius={0}
              animationDuration={0.5}
            />
          </div>
        </DefaultMenu>
        <div className='menu-tablet-only'>
          <VerticalMenu isOpen={isOpen}>
          <p onClick={() => window.location = '/about-us'}>Get to know us</p>
            <p onClick={() => window.location = '/case-studies/padoongrich'}>Case Study 1</p>
            {/* <p onClick={() => scroll(myRef.ourServicesRef.current.offsetTop - 70)} >OUR SERVICES</p>
            <p onClick={() => scroll(myRef.ourProjectsRef.current.offsetTop - 70)} >OUR PROJECTS</p>
            <p onClick={() => scroll(myRef.ourProjectsRef.current.offsetTop + myRef.howWeWorkRef.current.offsetTop - 50)} >HOW WE WORK</p>
            <p onClick={() => scroll(myRef.ourProjectsRef.current.offsetTop + myRef.theTeamRef.current.offsetTop - 50)} >THE TEAM</p> */}
            {!disableContact && <p onClick={() => scroll(myRef.ourProjectsRef.current.offsetTop + myRef.addressRef.current.offsetTop + 10)} >CONTACT</p>}
          </VerticalMenu>
        </div>
        <div className='menu-mobile-only'>
          <VerticalMenu isOpen={isOpen}>
            <p onClick={() => window.location = '/case-studies/padoongrich'}>Case Study 1</p>
            {/* <p onClick={() => scroll(myRef.ourServicesRef.current.offsetTop - 20)} >OUR SERVICES</p>
            <p onClick={() => scroll(myRef.ourProjectsRef.current.offsetTop - 0)} >OUR PROJECTS</p>
            <p onClick={() => scroll(myRef.ourProjectsRef.current.offsetTop + myRef.howWeWorkRef.current.offsetTop - 30)} >HOW WE WORK</p>
            <p onClick={() => scroll(myRef.ourProjectsRef.current.offsetTop + myRef.theTeamRef.current.offsetTop - 80)} >THE TEAM</p> */}
            {!disableContact && <p onClick={() => scroll(myRef.ourProjectsRef.current.offsetTop + myRef.addressRef.current.offsetTop - 30)} >CONTACT</p>}
          </VerticalMenu>
        </div>
      </FixMenuBar>
  )
}

export default Menu
