import { faL } from '@fortawesome/free-solid-svg-icons'
import React, { useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { WhoWeAreContainer } from '../components/styled'
import whoWeAreVideo from '../assets/videos/who-we-are.mp4'

const VideoContent = () => {
  const videoRef = useRef(null)
  const [isMuted, setIsMuted] = useState(false)
  const [showMouse, setShowMouse] = useState(false)
  const handleVideoClick = () => {
    videoRef.current.muted = !videoRef.current.muted
    setIsMuted(!isMuted)
  }

  const handleFullScreenClick = () => {
    videoRef.current.requestFullscreen()
  }

  const handleMouseMove = (e) => {
    const cursor = document.querySelector('.cursor')
    cursor.setAttribute(
      'style',
      `top: ${e.pageY}px; left: ${e.pageX}px;`,
    )
  }
  const handleMouseLeave = (e) => {
    const cursor = document.querySelector('.cursor')
    cursor.setAttribute(
      'style',
      'display: none',
    )
  }

  return (
  // <div className={isMuted ? 'doLoud' : 'doMute'}>
  <div style={{ width: '50%', height: 'auto', marginTop: '32px' }}>
  <div onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave} onClick={handleVideoClick} onMouseEnter={() => setShowMouse(true)} className='no-cursor'>
      <video
        className="video-background"
        autoPlay
        muted
        loop
        ref={videoRef}
        style={{ width: '100%', height: '100%' }}
      >
        <source src={whoWeAreVideo} type="video/mp4" />
      </video>
    { showMouse && <div className={`cursor ${isMuted ? 'doMute' : ''}`} /> }
    </div>
      <div
      onClick={handleFullScreenClick}
      className="content"
      style={{
        display: 'flex', justifyContent: 'flex-end', margin: '8px 4px 0 0', fontSize: '20px',
      }}>
      <FontAwesomeIcon icon={solid('expand')} style={{ marginLeft: 8, color: 'white' }}/>
      </div>
      </div>
  )
}

export const WhoWeAre = () => (<WhoWeAreContainer>
    <VideoContent className='menu-tablet-only' />
    <p className='header'>WHO WE ARE</p>
    <p className='motto'>Together <span style={{ color: '#3CD2AF' }}>we will improve your <br className="mobile-only"></br>work</span> process by <br className="mobile-only"></br>introducing ERP Systems</p>
    <p className='detail'>such as Inventory Management, Invoice-Receipt System, Accounting, HR System, and other customized work flow programs.
     Or if you’re looking for a Technical Partner, we specialize in making MVP, Prototypes, Mockups, Designs, etc.</p>
  </WhoWeAreContainer>
)

export default WhoWeAre
